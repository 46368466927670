
import { Clipboard } from "@angular/cdk/clipboard";
import { DatePipe } from "@angular/common";
import { Component, EventEmitter, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { CellClickedEvent, ColDef, ColumnMovedEvent, ColumnResizedEvent, ColumnVisibleEvent, GetContextMenuItemsParams, GridReadyEvent, ICellRendererParams, MenuItemDef, RowSelectedEvent } from "ag-grid-community";
import { catchError, debounceTime } from "rxjs/operators";
import { AGTableBase } from "../../shared/components/table/ag-table-base";
import { Invoice } from "../../shared/models/invoice.model";
import { AuthUser, PaymentType } from "../../shared/models/user.model";
import { CustomUtils } from "../../shared/services/custom-utils";
import { InvoiceActionData, InvoiceRequestParams, InvoiceService } from '../../shared/services/invoice.service';
import { LocalStorageService } from "../../shared/services/localStorage.service";
import { ModalService } from "../../shared/services/modal.service";
import { NotificationService } from '../../shared/services/notification.service';
import { UsersService } from '../../shared/services/users.service';
import { InvoiceTableActionsComponent } from "./invoice-table-actions/invoice-table-actions.component";

@Component({
    selector: 'app-invoices-table',
    templateUrl: './invoices-table.component.html',
    styleUrls: ['./invoices-table.component.scss'],
})
export class InvoicesTableComponent extends AGTableBase implements OnInit {

    @Output() actions: EventEmitter<InvoiceActionData> = new EventEmitter();
    @Output() onAfterDelete: EventEmitter<void> = new EventEmitter();
    @Output() changeBatch = new EventEmitter<Invoice[]>();

    @ViewChild('deleteModalTpl', { read: TemplateRef, static: false }) deleteModalTpl: any;

    requestParams = new InvoiceRequestParams();
    user: AuthUser;
    loading: boolean = false;

    batchSelectedIds: Set<number> = new Set();

    // ng grid
    columnDefs: ColDef[] = [
        {
            headerName: '', field: 'batch', checkboxSelection: true,
            maxWidth: 45, headerCheckboxSelection: true, pinned: 'left',
            lockPinned: true, lockPosition: 'left', lockVisible: true,
            suppressColumnsToolPanel: true, suppressMenu: true, suppressMovable: true
        },
        {
            headerName: 'ID', field: 'id',
            hide: true, minWidth: 100,
            cellRenderer: (params: ICellRendererParams) => {
                const _ = params.data;
                return `<div class="two-lines">${_.id}</div>`;
            },
        },
        {
            headerName: 'Status', field: 'status',
            maxWidth: 75, minWidth: 75,
            cellClass: 'd-flex justify-content-center align-items-center',
            cellRenderer: (params: ICellRendererParams) => {
                const _ = params.data;
                return `<div class="one-line">${this.paymentStatusFormat(_)}</div>`;
            },
        },
        {
            headerName: 'Created on', field: 'createdAt',
            minWidth: 100, maxWidth: 110,
            cellRenderer: (params: ICellRendererParams) => {
                const _ = params.data;
                const date = CustomUtils.formatDate(_.createdAt);
                const autoGenerated = _.autoGenerated ? '<i class="icon icon-robot fs-4 text-primary-p8" title="Auto generate"></i>' : '';
                return `<div class="d-flex justify-content-between align-items-center gap-10"> 
                    <div class="two-lines ${_.autoGenerated ? 'text-primary-p8' : ''}"> ${date[1]}<br>${date[0]}</div>  
                    ${autoGenerated} 
                </div>`;
            },
        },
        {
            headerName: 'Paid on', field: 'paidAt',
            minWidth: 90, maxWidth: 90,
            cellRenderer: (params: ICellRendererParams) => {
                const _ = params.data;
                if (_.paidAt) {
                    const date = CustomUtils.formatDate(_.paidAt);
                    return `<div class="two-lines">${date[1]}<br>${date[0]}</div>`;
                }
                return '';
            },
        },
        {
            headerName: 'Amount (EUR)', field: 'totalCommitment',
            minWidth: 90, maxWidth: 90,
            cellRenderer: (params: ICellRendererParams) => {
                const _ = params.data;
                return `<div class="one-line">${_.totalCommitment}</div>`;
            },
        },
        {
            headerName: 'MT Rate (EUR)', field: 'rateApplied',
            minWidth: 90, maxWidth: 90,
            cellRenderer: (params: ICellRendererParams) => {
                const _ = params.data;
                return `<div class="one-line">${_.rateApplied}</div>`;
            },
        },
        {
            headerName: 'Package', field: 'pricingGroupTitle',
            minWidth: 110,
            cellRenderer: (params: ICellRendererParams) => {
                const _ = params.data;
                return `<div class="one-line">${_.pricingGroupTitle}</div>`;
            },
        },
        {
            headerName: 'Invoice number', field: 'zohoInvoiceNumber',
            minWidth: 110, hide: true,
            cellRenderer: (params: ICellRendererParams) => {
                const _ = params.data;
                return `<div class="two-lines">${_.zohoInvoiceNumber ?? ''}</div>`;
            },
        },
        {
            headerName: 'Created by', field: 'createdByEmail',
            minWidth: 110,
            cellRenderer: (params: ICellRendererParams) => {
                const _ = params.data;
                return `<div class="two-lines">${_.createdByEmail ? _.createdByEmail : ''}</div>`;
            },
        },
        {
            headerName: 'User', field: 'userEmail',
            minWidth: 110,
            cellRenderer: (params: ICellRendererParams) => {
                const _ = params.data;
                return `<div class="two-lines">${_.userEmail}</div>`;
            },
        },
        {
            headerName: 'Company', field: 'companyName',
            minWidth: 80, hide: true,
            cellRenderer: (params: ICellRendererParams) => {
                const _ = params.data;
                return `<div class="two-lines">${_.companyName}</div>`;
            },
        },
        {
            headerName: 'Owner', field: 'ownerEmail',
            hide: true,
            cellRenderer: (params: ICellRendererParams) => {
                const _ = params.data;
                return `<div class="two-lines">${_.ownerEmail ? _.ownerEmail : ''}</div>`;
            },
        },
        {
            headerName: 'Email sent', field: 'emailSent',
            minWidth: 70, maxWidth: 70,
            cellClass: 'd-flex justify-content-center align-items-center',
            cellRenderer: (params: ICellRendererParams) => {
                const _ = params.data;
                return `<div class="one-line">${this.emailSentStatusFormat(_)}</div>`;
            },
        },
        {
            headerName: 'Payment', field: 'paymentValue',
            cellClass: 'd-flex justify-content-center align-items-center',
            onCellClicked: (event: CellClickedEvent) => this.onPaymentClick(event),
            cellRenderer: (params: ICellRendererParams) => {
                const _ = params.data;
                return `<div class="one-line">${this.paypalFormat(_)}</div>`;
            },
            minWidth: 150, maxWidth: 150,
        },
        {
            headerName: 'Actions', field: 'actions', maxWidth: 180, minWidth: 180,
            pinned: 'right', lockPinned: true, lockPosition: 'right', lockVisible: true,
            suppressColumnsToolPanel: false, suppressMenu: false, suppressAutoSize: true,
            tooltipField: 'actions',
            headerTooltip: 'Right click on a row to see more options.',
            headerClass: 'action-cell',
            cellClass: 'action-cell',
            cellRenderer: InvoiceTableActionsComponent,
            onCellClicked: (event: CellClickedEvent) => this.onActionClick(event),
        }
    ];

    rowData!: Invoice[];
    // ng grid

    constructor(
        public notificationService: NotificationService,
        public service: InvoiceService,
        public userService: UsersService,
        public modal: ModalService,
        private datePipe: DatePipe,
        private localStorage: LocalStorageService,
        private clipBoard: Clipboard
    ) {
        super();
        this.notificationService = notificationService;
        this.modal = modal;
        this.service = service;
        this.userService = userService;
    }

    ngOnInit() {
        this.loading = true;
        this.userService.getAuthUser().then(user => {
            this.user = user;
            const role = user.role;
            let hideColumns = [];
            this.columnDefs = this.columnDefs.filter(c => !hideColumns.includes(c.headerName));
            if (role !== 'admin') {
                const exclude = ['userEmail', 'emailSent'];
                hideColumns.push(...exclude);
            }
            if (role !== 'mainaccount' || user.paymentType === PaymentType.POSTPAID) {
                hideColumns.push('paymentValue');
            }
            this.columnDefs = this.columnDefs.filter(c => !hideColumns.includes(c.field));
            this.paginationPageSize = this.localStorage.get(`invoice_table_component_size_${this.user.id}`) || 20;
        }).catch(e => {
            console.log(e)
        });
        this.actionSubscription = this.service.action$.subscribe(
            action => {
                this.onAction(action);
            }
        );
        this.columnChange$.pipe(
            debounceTime(1000)
        ).subscribe((event: ColumnMovedEvent | ColumnResizedEvent | ColumnVisibleEvent) => {
            const currentColumnState = this.gridApi.getColumnState();
            this.localStorage.set(`invoice_table_state_${this.user.id}`, currentColumnState);
        });
    }

    update(loading = true) {
        this.loading = loading;
        this.requestParams.size = this.paginationPageSize;
        this.requestParams.page = this.currentPageNumber - 1;
        this.service.all(this.requestParams).pipe(
            catchError(error => {
                this.loading = false;
                this.notificationService.error({
                    title: 'Invoices',
                    message: 'An error occurred while loading the invoices',
                    serviceName: 'API GATEWAY',
                    requestMessage: error.statusText,
                    requestCode: error.status
                });
                throw error;
            })
        ).subscribe(collection => {
            collection.content.forEach(invoice => {
                if (invoice.void) {
                    invoice.status = 'VOID';
                }
            });
            this.rowData = collection.content;
            this.totalRowsCount = collection.totalElements;

            this.loading = false;
        });
    }

    onAction(event: InvoiceActionData) {
        if (event.name === 'delete') {
            let dialogRef = this.modal.alert().size('sm').component(this.deleteModalTpl).open();
            dialogRef.result.then(result => {
                if (result) {
                    this.delete(event.row);
                }
            });
        }

        if (event.name === 'reportDownload') {
            let url = this.service.getReportUrl(event.row.reportReference);
            window.open(url, '_blank');
        }

        if (event.name === 'generateZohoReport') {
            this.loading = true;
            const hasZohoId = !!event.row.zohoId;
            this.service.createZohoInvoice(event.row.id).subscribe(() => {
                this.update();
                this.notificationService.success(hasZohoId ? 'Zoho report has been updated' : 'Zoho report has been generated', 'Invoices');
            }, error => {
                this.loading = false;
                this.notificationService.error({
                    title: 'Invoices',
                    message: 'An error occurred while creating Zoho invoice',
                    serviceName: 'API GATEWAY',
                    requestMessage: error.statusText,
                    requestCode: error.status
                });
            });
        }

        if (event.name === 'downloadZohoReport') {
            let url = this.service.getZohoReportUrl(event.row.id);
            window.open(url, '_blank');
        }

        if (event.name === 'sendZohoReport') {
            this.loading = true;
            this.service.sendZohoInvoice(event.row.id).subscribe(() => {
                this.loading = false;
                this.notificationService.success('Zoho report has been sent', 'Invoices');
            }, error => {
                this.loading = false;
                this.notificationService.error({
                    title: 'Invoices',
                    message: 'An error occurred',
                    serviceName: 'API GATEWAY',
                    requestMessage: error.statusText,
                    requestCode: error.status
                });
            });
        }

        this.actions.emit(event);
    }

    changeSize($event, size) {
        this.paginationPageSize = size;
        this.localStorage.set(`invoice_table_component_size_${this.user.id}`, size);
        this.update();
    }

    onPaymentClick(event: CellClickedEvent) {
        const colID = event.column.getColId();
        const eventTarget = event.event.target as HTMLElement;
        console.log(colID)
        if (eventTarget.classList.contains('btn-paypal') && colID === 'paymentValue') {
            this.actions.emit({
                column: colID,
                name: 'paypalPayment',
                row: event.data
            });
        }
    }

    delete(invoice: any): void {
        this.loading = true;
        const deleteMethod = invoice.status === 'WAITING' ? this.service.delete(invoice.id) : this.service.deleteByAdmin(invoice.id);
        deleteMethod.subscribe({
            next: () => {
                this.notificationService.success('Invoice removed', 'Invoices');
                this.onAfterDelete.emit();
                this.update();
            },
            error: () => this.loading = false,
            complete: () => this.loading = false
        });
    }


    canShowActionEdit(row: Invoice) {
        return row.status === 'WAITING';
    }

    paymentStatusFormat(invoice: Invoice) {
        const statusMap = {
            WAITING: '<span class="icon icon-wait fs-3" title="Waiting"></span>',
            PROCESSING: '<span class="icon icon-wait fs-3 text-warning-w2" title="processing"></span>',
            CONFIRMED: '<span class="icon icon-positive text-sucess-s2 fs-3" title="Confirmed"></span>',
            VOID: '<span class="icon icon-close text-error-r2 fs-4" title="Void"></span>',
            PARTIALLY_PAID: '<span class="icon icon-check text-warning-w2 fs-4" title="Partially paid"></span>',
        };
        return statusMap[invoice.status];
    }

    emailSentStatusFormat(invoice: Invoice) {
        if (invoice.emailSent == true) {
            return '<span class="icon icon-check text-sucess-s2 fs-4" title="Yes"></span>';
        } else {
            return '<span class="icon icon-close fs-4 text-error-r3" title="No"></span>';
        }
    }

    paypalFormat(invoice: Invoice) {
        if (invoice.status === 'WAITING' || invoice.status === 'PARTIALLY_PAID') {
            return '<div class="btn btn-paypal btn-primary"><i class="fab fa-paypal"></i> Payment</div>';
        }
        return '';
    }

    resetBatch() {
        this.batchSelectedIds.clear();
        this.gridApi.deselectAll();
    }

    getContextMenuItems = (
        params: GetContextMenuItemsParams
    ): (MenuItemDef)[] => {
        const currentRowClicked = params.node.data;
        const result: (MenuItemDef)[] = [
            {
                name: 'Copy',
                icon: '<span class="icon-copy fs-4"></span>',
                action: () => {
                    const renderedValue = this.getRenderedCellValue(params);
                    this.clipBoard.copy(renderedValue ? renderedValue : currentRowClicked.id);
                },
            },
        ];
        return result;
    }

    onRowSelected(event: RowSelectedEvent) {
        const selectedRows = this.gridApi.getSelectedRows();
        this.batchSelectedIds = new Set(selectedRows.map(row => row.id));
        this.changeBatch.emit(selectedRows);
    }

    onGridReady(params: GridReadyEvent) {
        this.gridApi = params.api;
        this.update();
        const columnState = this.localStorage.get(`invoice_table_state_${this.user.id}`);
        this.gridApi.applyColumnState({ state: columnState, applyOrder: true });
    }

    ngOnDestroy() {
        this.actionSubscription.unsubscribe();
    }
}
