
import {
    Component,
    Output,
    EventEmitter,
    ViewChild,
    Input, TemplateRef, OnInit
} from '@angular/core';
import { FormBuilder, FormGroup, ValidatorFn } from '@angular/forms';
import { NotificationService } from '../../../shared/services/notification.service';
import { ValidationService, Validators as V } from '../../../shared/services/validation.service';
import { AnalyticsTariff, Bundle, SpecialPricingGroup, Tier } from "../../../shared/models/pricing.model";
import { PricingService } from "../../../shared/services/pricing.service";
import { SchedulerService } from "../../../shared/services/scheduler.service";
import { CustomUtils } from '../../../shared/services/custom-utils';
import { UsersService } from '../../../shared/services/users.service';
import { DialogRef, ModalService } from "../../../shared/services/modal.service";
declare var moment: any;

@Component({
    selector: 'app-special-pricing-group-form',
    templateUrl: './special-pricing-group-form.component.html',
    styleUrls: ['./special-pricing-group-form.component.scss', '../../pricing.scss']
})

export class SpecialPricingGroupFormComponent implements OnInit {

    @Input() backButtonShow = true;
    @Input() model: SpecialPricingGroup;

    @Output() onAfterSave = new EventEmitter();

    @ViewChild('tierModalTpl', { read: TemplateRef, static: false }) tierModalTpl: any;
    tierModal: DialogRef;

    @ViewChild('bundleModalTpl', { read: TemplateRef, static: false }) bundleModalTpl: any;
    bundleModal: DialogRef;

    @ViewChild('usersModalTpl', { read: TemplateRef, static: false }) usersModalTpl: any;
    usersModal: DialogRef;

    @ViewChild('analyticsModalTpl', { read: TemplateRef, static: false }) analyticsModalTpl: any;
    analyticsModal: DialogRef;

    services = PricingService.services;
    modelTier: Tier & { index?: number, isNew?: boolean };
    modelBundle: Bundle & { index?: number };
    modelAnalyticsTariff: AnalyticsTariff;
    tierQuotaMin = 1;

    validForSecondsUnit = 'unlimited';
    validForSecondsValue = null;

    loading = false;

    form: FormGroup;
    formBundle: FormGroup;
    formTier: FormGroup;
    formAnalyticsTariff: FormGroup;
    packagesCount: number;

    constructor(
        public notificationService: NotificationService,
        private formBuilder: FormBuilder,
        public service: PricingService,
        public userService: UsersService,
        public validationService: ValidationService,
        public modal: ModalService
    ) {
        this.model = service.createSpecialGroup();

        this.form = formBuilder.group({
            title: ['', V.compose([V.required, V.minLength(1), V.maxLength(255)])],
            paymentType: ['', V.compose([V.required])],
            visibleForAdminsOnly: [''],
            enabled: [''],
            packagesCount: ['', V.compose([V.min(1, false)])]
        });

        this.initializeValidationRules(validationService);
        this.formBundle = this.createFormBundleMT();
        this.formTier = this.createFormTier();
        this.formAnalyticsTariff = this.createFormAnalyticsTariff();
        this.subscribeToFormTierChanges();
    }

    private initializeValidationRules(validationService: ValidationService): void {
        validationService.addFormatter(
            'visibleRange',
            validationService.createTextFormatter('"Pricing visible from" must be less than "Visible until"')
        );
        validationService.addFormatter(
            'visibleFromMin',
            validationService.createTextFormatter('"Pricing visible from" must be current date or a date in the future.')
        );
        validationService.addFormatter(
            'validRatePerUnitCommitmentEqual',
            validationService.createTextFormatter('"Rate per product" should be equal to "Top up value" in statistics')
        );
        validationService.addFormatter(
            'uniqueQuota',
            validationService.createTextFormatter('This value already exists (quota, rate per product/test, valid from)')
        );
        validationService.addFormatter(
            'serviceTypeRequire',
            validationService.createTextFormatter('Must select a product type')
        );
    }

    private createFormBundleMT(): FormGroup {
        return this.formBuilder.group({
            serviceType: ['', V.compose([V.required])],
            ratePerUnit: [
                '',
                V.compose([
                    V.required,
                    V.digitsAndDot(true),
                    V.min(0.001, false),
                    V.price(3, true),
                    this.validRatePerUnitCommitmentEqual('ratePerUnit')
                ])
            ],
            commitment: [
                '',
                V.compose([
                    V.required,
                    V.digitsAndDot(true),
                    V.min(0.001, false),
                    V.price(3, true),
                    this.validRatePerUnitCommitmentEqual('commitment')
                ])
            ],
            visibleFrom: ['', V.compose([this.visibleFromMin])],
            visibleUntil: ['', V.compose([this.visibleRangeValidator])],
            validForSeconds: ['']
        });
    }

    private createFormBundleNonMT(): FormGroup {
        return this.formBuilder.group({
            serviceType: ['', V.compose([V.required])],
            ratePerUnit: [
                '',
                V.compose([
                    V.required,
                    V.digitsAndDot(true),
                    V.min(0.001, false),
                    V.price(3, true),
                    this.validRatePerUnitCommitmentEqual('ratePerUnit')
                ])
            ],
        });
    }

    private createFormTier(): FormGroup {
        return this.formBuilder.group({
            serviceType: ['', V.compose([V.required])],
            ratePerUnit: [
                '',
                V.compose([
                    V.required,
                    V.digitsAndDot(true),
                    V.min(0.001, false),
                    V.price(3, true),
                    this.validRatePerUnitCommitmentEqual('ratePerUnit')
                ])
            ],
            commitment: [
                '',
                V.compose([V.digitsAndDot(true), V.price(3, true), this.validRatePerUnitCommitmentEqual('commitment')])
            ],
            quota: ['', V.compose([this.dynamicMinQuota])],
            visibleFrom: ['', V.compose([this.visibleRangeValidator])],
            visibleUntil: ['', V.compose([this.visibleRangeValidator])]
        });
    }

    private createFormAnalyticsTariff(): FormGroup {
        return this.formBuilder.group({
            rate: [
                '',
                V.compose([V.required, V.digitsAndDot(true), V.min(0.001, false), V.price(3, true)])
            ],
            visibleFrom: ['', V.compose([this.createVisibleRangeAnalyticsValidator(), this.createVisibleFromMinAnalyticsValidator()])],
            visibleUntil: ['', V.compose([this.createVisibleRangeAnalyticsValidator()])]
        });
    }

    private subscribeToFormTierChanges(): void {
        this.formTier.valueChanges.subscribe(() => {
            const values = this.parseFormTierValues(this.formTier.value);
            const exists = this.model.tiers.filter(t => {
                if (t.hidden) {
                    return false;
                }
                return this.compareFormValues(values, this.parseFormTierValues(t));
            }).length > 1;
            this.formTier.controls.quota.setErrors(exists ? { uniqueQuota: false } : null);
        });
    }

    private visibleRangeValidator = () => {
        const model = this.model.paymentType === 'PREPAID' ? this.modelBundle : this.modelTier;
        if (!model || !model.visibleFrom || !model.visibleUntil || model.id) {
            return null;
        }
        const visibleFrom = new Date(model.visibleFrom);
        const visibleUntil = new Date(model.visibleUntil);
        if (visibleFrom > visibleUntil) {
            return { visibleRange: false };
        }
        return null;
    }

    private visibleFromMin = () => {
        const model = this.model.paymentType === 'PREPAID' ? this.modelBundle : this.modelTier;
        if (!model || !model.visibleFrom || model.id) {
            return null;
        }
        const visibleFrom = new Date(model.visibleFrom);
        const now = new Date();
        now.setHours(0, 0, 0, 0);

        if (visibleFrom < now) {
            return { visibleFromMin: false };
        }

        return null;
    }

    private validRatePerUnitCommitmentEqual = (inputType: string) => {
        return (v) => {
            const model = this.model.paymentType === 'PREPAID' ? this.modelBundle : this.modelTier;
            if (!model || model.serviceType !== 'STATISTICS') {
                return null;
            }
            const value = v.value ? v.value : null;

            let ratePerUnit = model.ratePerUnit;
            let commitment = value;
            if (inputType === 'ratePerUnit') {
                ratePerUnit = value;
                commitment = model.commitment;
            }
            if (ratePerUnit !== commitment) {
                return { validRatePerUnitCommitmentEqual: false };
            }
            return null;
        }
    }

    private dynamicMinQuota = (v) => {
        if ((this.modelTier && this.modelTier.isFirst) || (this.modelTier && this.modelTier.serviceType === 'STATISTICS')) {
            return null;
        }
        if (this.modelTier && !this.modelTier.serviceType) {
            return { serviceTypeRequire: { required: 0 } };
        }
        let value = v.value !== undefined ? parseInt(v.value) : 0;
        if (!value) {
            value = 0;
        }
        if (value < 0) {
            return { min: { required: 0 } };
        }
        return null;
    }

    private parseFormTierValues(values: any): any {
        return {
            quota: parseInt(values.quota),
            ratePerUnit: parseFloat(values.ratePerUnit),
            visibleFrom: values.visibleFrom ? values.visibleFrom : this.formatDate(new Date()),
            serviceType: values.serviceType
        };
    }

    private compareFormValues(a: any, b: any): boolean {
        for (let i in a) {
            if (a[i] !== b[i]) {
                return false;
            }
        }
        return true;
    }

    private createVisibleRangeAnalyticsValidator() {
        return () => {
            const model = this.modelAnalyticsTariff;
            if (!model || !model.visibleFrom || !model.visibleUntil || model.id) {
                return null;
            }
            const visibleFrom = new Date(model.visibleFrom);
            const visibleUntil = new Date(model.visibleUntil);
            if (visibleFrom > visibleUntil) {
                return { visibleRange: false };
            }
            return null;
        };
    }

    private createVisibleFromMinAnalyticsValidator() {
        return () => {
            const model = this.modelAnalyticsTariff;
            if (!model || !model.visibleFrom || model.id) {
                return null;
            }
            const visibleFrom = new Date(model.visibleFrom);
            const now = new Date();
            now.setHours(0, 0, 0, 0);
            if (visibleFrom < now) {
                return { visibleFromMin: false };
            }
            return null;
        };
    }

    formatDate(d: Date) {
        return d.getFullYear() + '-' + ("0" + (d.getMonth() + 1)).slice(-2) + '-' + ("0" + d.getDate()).slice(-2);
    }

    ngOnInit() {
    }

    isFormValid() {
        if (this.model.users.length < 1) {
            return false;
        }
        return this.form.valid;
    }

    isBundleApplyDisabled(): boolean {
        if (this.modelBundle.serviceType === 'MT') {
            return !this.formBundle.valid;
        } else {
            return !this.formBundle.get('ratePerUnit').valid;
        }
    }

    getCommitment(row: Bundle): number {
        if (row.serviceType === 'MT') {
            return row.commitment;
        }
        return this.model.bundles.find(_ => _.serviceType === 'MT')?.commitment ?? 0;
    }

    getVisibleFrom(row: Bundle): string {
        if (row.serviceType === 'MT') {
            return row.visibleFrom;
        }
        return this.model.bundles.find(_ => _.serviceType === 'MT')?.visibleFrom ?? '';
    }

    getVisibleUntil(row: Bundle): string | Date {
        if (row.serviceType === 'MT') {
            return row.visibleUntil;
        }
        return this.model.bundles.find(_ => _.serviceType === 'MT')?.visibleUntil ?? '';
    }

    addBundle() {
        this.formBundle.reset();
        this.modelBundle = this.service.createBundle();
        this.modelBundle.serviceType = this.services[0];
        const time = SchedulerService.secondsToHuman(this.modelBundle.validForSeconds);
        this.validForSecondsUnit = time.unit;
        this.validForSecondsValue = time.value;
        this.bundleModal = this.modal.alert().isBlocking(true).component(this.bundleModalTpl).open();
        this.updatePackagesCount();
    }

    editBundle(bundle) {
        let model = Object.assign({}, bundle);
        model.index = this.model.bundles.indexOf(bundle);
        this.modelBundle = model;
        this.validForSecondsUnit = 'unlimited';
        this.validForSecondsValue = null;
        if (bundle.validForSeconds) {
            const time = SchedulerService.secondsToHuman(bundle.validForSeconds);
            this.validForSecondsUnit = time.unit;
            this.validForSecondsValue = time.value;
        }
        this.bundleModal = this.modal.alert().isBlocking(true).component(this.bundleModalTpl).open();
    }

    deleteBundle(bundle) {
        if (!this.model.id || !bundle.id) {
            this.model.bundles = this.model.bundles.filter(_ => _ !== bundle);
        } else {
            this.model.bundles = this.model.bundles.filter(_ => _.id !== bundle.id);
        }
        this.updatePackagesCount();
    }

    onChangeVisibleFromBundle(time) {
        if (time === null) {
            this.modelBundle.visibleFrom = null;
            this.formBundle.controls.visibleFrom.patchValue('');
        }
        if (!parseInt(time)) {
            return;
        }
        let value = moment.unix(time).format("YYYY-MM-DD");
        this.modelBundle.visibleFrom = value;
        this.formBundle.controls.visibleFrom.patchValue(value);
    }

    onChangeVisibleUntilBundle(time) {
        if (time === null) {
            this.modelBundle.visibleUntil = null;
            this.formBundle.controls.visibleUntil.patchValue('');
        }
        if (!parseInt(time)) {
            return;
        }
        let value = moment.unix(time).format("YYYY-MM-DD");
        this.modelBundle.visibleUntil = value;
        this.formBundle.controls.visibleUntil.patchValue(value);
    }

    onChangeValidForSeconds(value) {
        if (!value.unit) {
            return;
        }
        this.modelBundle.validForSeconds = SchedulerService.repeatEveryToSeconds(value.value, value.unit);
        this.formBundle.controls.validForSeconds.patchValue(this.modelBundle.validForSeconds);
    }

    formatValidForSeconds(row: Bundle): string {
        let sec = row.validForSeconds;
        if (row.serviceType !== 'MT') {
            sec = this.model.bundles.find(_ => _.serviceType === 'MT')?.validForSeconds ?? null;
        }
        if (!sec) {
            return 'Unlimited'
        }
        return PricingService.formatValidForSeconds(sec);
    }

    onSubmitBundle() {
        let model = Object.assign({}, this.modelBundle);
        if (!('index' in model)) {
            this.model.bundles.push(model);
        } else {
            const index = model.index;
            delete model.index;
            this.model.bundles[index] = model;
        }
        this.bundleModal.close();
        this.updatePackagesCount();
    }

    addTier() {
        this.modelTier = this.service.createTier();
        this.modelTier.isNew = true;
        this.modelTier.serviceType = this.services[0];
        if (this.getTiersCount() === 0) {
            this.modelTier.isFirst = true;
        }

        this.tierModal = this.modal.alert().isBlocking(true).component(this.tierModalTpl).open();
    }

    editTier(tier) {
        /*this.model.tiers.map((tier, index) => {
             tier.isFirst = index === 0;
         });*/
        let model = { index: this.model.tiers.indexOf(tier) };
        for (let i in tier) { model[i] = tier[i]; }
        this.modelTier = model;
        this.modelTier.isNew = false;
        this.tierModal = this.modal.alert().isBlocking(true).component(this.tierModalTpl).open();
        this.updatePackagesCount();
    }

    onChangeVisibleFromTier(time) {
        if (time === null) {
            this.modelTier.visibleFrom = null;
            this.formTier.controls.visibleFrom.patchValue('');
        }
        if (!parseInt(time)) {
            return;
        }
        let value = moment.unix(time).format("YYYY-MM-DD");
        this.modelTier.visibleFrom = value;
        this.formTier.controls.visibleFrom.patchValue(value);
    }

    onChangeVisibleUntilTier(time) {
        if (time === null) {
            this.modelTier.visibleUntil = null;
            this.formTier.controls.visibleUntil.patchValue('');
        }
        if (!parseInt(time)) {
            return;
        }
        let value = moment.unix(time).format("YYYY-MM-DD");
        this.modelTier.visibleUntil = value;
        this.formTier.controls.visibleUntil.patchValue(value);
    }

    deleteTier(tier) {
        if (!this.model.id || !tier.id) {
            this.model.tiers = this.model.tiers.filter(_ => _ !== tier);
        } else {
            this.model.tiers = this.model.tiers.filter(_ => _.id !== tier.id);
        }
        this.updatePackagesCount();
    }

    onSubmitTier() {
        let model = Object.assign({}, this.modelTier);
        if (!('index' in model)) {
            this.model.tiers.push(model);
        } else {
            const index = model.index;
            delete model.index;
            this.model.tiers[index] = model;
        }
        this.model.tiers.map((tier, index) => {
            tier.isFirst = index === 0;
        })
        this.tierModal.close();
        this.updatePackagesCount();
    }

    addAnalytics() {
        this.modelAnalyticsTariff = this.service.createAnalyticsTariff();
        this.analyticsModal = this.modal.alert().isBlocking(true).component(this.analyticsModalTpl).open();
    }

    editAnalytics(tariff: AnalyticsTariff) {
        let model = Object.assign({}, tariff);
        model.index = this.model.analyticsTariffs.indexOf(tariff);
        this.modelAnalyticsTariff = model;
        this.analyticsModal = this.modal.alert().isBlocking(true).component(this.analyticsModalTpl).open();
    }

    deleteAnalytics(tariff: AnalyticsTariff) {
        if (!this.model.id || !tariff.id) {
            this.model.analyticsTariffs = this.model.analyticsTariffs.filter(_ => _ !== tariff);
        } else {
            this.model.analyticsTariffs = this.model.analyticsTariffs.filter(_ => _.id !== tariff.id);
        }
    }

    onChangeVisibleFromAnalytics(time) {
        if (time === null) {
            this.modelAnalyticsTariff.visibleFrom = null;
            this.formAnalyticsTariff.controls.visibleFrom.patchValue('');
        }
        if (!parseInt(time)) {
            return;
        }
        let value = moment.unix(time).format("YYYY-MM-DD");
        this.modelAnalyticsTariff.visibleFrom = value;
        this.formAnalyticsTariff.controls.visibleFrom.patchValue(value);
    }

    onChangeVisibleUntilAnalytics(time) {
        if (time === null) {
            this.modelAnalyticsTariff.visibleUntil = null;
            this.formAnalyticsTariff.controls.visibleUntil.patchValue('');
        }
        if (!parseInt(time)) {
            return;
        }
        let value = moment.unix(time).format("YYYY-MM-DD");
        this.modelAnalyticsTariff.visibleUntil = value;
        this.formAnalyticsTariff.controls.visibleUntil.patchValue(value);
    }

    onSubmitAnalytics() {
        let model = Object.assign({}, this.modelAnalyticsTariff);
        if (!('index' in model)) {
            this.model.analyticsTariffs.push(model);
        } else {
            const index = model.index;
            delete model.index;
            this.model.analyticsTariffs[index] = model;
        }
        this.analyticsModal.onDestroy.subscribe(() => {
            this.modelAnalyticsTariff = null;
        });
        this.analyticsModal.close();
    }

    onClickAddUser() {
        this.usersModal = this.modal.alert().component(this.usersModalTpl).open();
    }

    onClickRemoveUser(user) {
        if (user instanceof Event) {
            return;
        }
        this.model.users = this.model.users.filter(_ => _.userId !== user.userId);
    }

    onSelectUser(user) {
        if (user instanceof Event) {
            return;
        }
        if (!user.userId && user.id) {
            user.userId = user.id;
        }
        this.model.users.push(user);
    }

    onUnSelectUser(user) {
        if (user instanceof Event) {
            return;
        }
        this.model.users = this.model.users.filter(_ => _.id !== user.id);
    }

    getTiersCount() {
        return this.model.tiers.filter(_ => !_.hidden).length;
    }

    getTiersByServiceType(serviceType) {
        return this.model.tiers.filter(_ => !_.hidden && _.serviceType === serviceType)
    }

    getTiers() {
        let tiers = [];
        this.services.map(serviceType => {
            let serviceTypeTiers = this.getTiersByServiceType(serviceType);
            serviceTypeTiers.sort((a, b) => {
                if (a.quota > b.quota) { return 1; }
                if (a.quota < b.quota) { return -1; }
                return 0;
            })

            serviceTypeTiers.map((t, index) => {
                t.isFirst = index === 0;
                tiers.push(t)
            })
        });

        return tiers;
    }

    onChangeTierServiceType(event) {
        if (this.modelTier.serviceType === 'STATISTICS') {
            this.modelTier.quota = 0;
            if (this.modelTier.ratePerUnit) {
                this.modelTier.commitment = this.modelTier.ratePerUnit;
                this.formTier.controls.commitment.patchValue(this.modelTier.commitment)
            }
        } else {
            this.modelTier.isFirst = this.getTiersByServiceType(this.modelTier.serviceType).length === 0;
            if (this.modelTier.isFirst) {
                this.modelTier.quota = 0;
            }
        }
        this.formTier.controls.quota.patchValue(this.modelTier.quota)
    }

    onChangeBundleServiceType(event) {
        if (this.modelBundle.serviceType === 'MT' || this.modelBundle.serviceType === 'MO') {
            const validForSeconds = this.getDefaultValueValidForSecondsForBundle();
            if (validForSeconds) {
                const time = SchedulerService.secondsToHuman(validForSeconds);
                this.validForSecondsUnit = time.unit;
                this.validForSecondsValue = time.value;
                this.modelBundle.validForSeconds = validForSeconds;
                this.formBundle.controls.validForSeconds.patchValue(validForSeconds);
            }
        }
        if (this.modelBundle.serviceType === 'STATISTICS') {
            if (this.modelBundle.ratePerUnit) {
                this.modelBundle.commitment = this.modelBundle.ratePerUnit;
                this.formBundle.controls.commitment.patchValue(this.modelBundle.commitment)
            }
        }
    }

    onChangeBundleRatePerUnit(event) {
        if (this.modelBundle.serviceType === 'STATISTICS') {
            this.modelBundle.commitment = this.modelBundle.ratePerUnit;
            this.formBundle.controls.commitment.patchValue(this.modelBundle.commitment)
        }

    }

    onChangeTierRatePerUnit(event) {
        if (this.modelTier.serviceType === 'STATISTICS') {
            this.modelTier.commitment = this.modelTier.ratePerUnit;
            this.formTier.controls.commitment.patchValue(this.modelTier.commitment)
        }
    }

    getDefaultValueValidForSecondsForBundle() {
        const values = this.model.bundles.filter(_ => _.serviceType === 'MO' || _.serviceType === 'MT').map(_ => _.validForSeconds).filter(_ => _);
        if (values.length && values[0]) {
            return values[0];
        }
        return null;
    }

    onSubmit() {
        if (!this.model.users.length) {
            this.modal.confirm('Pricing Groups', 'The customers list is empty. Continue?')
                .size('sm')
                .open()
                .result.then(result => {
                    if (result) {
                        this.save();
                    }
                });
        } else {
            this.save();
        }
    }

    save() {
        this.loading = true;
        let model = Object.assign({}, this.model);
        model.tiers = [];
        model.bundles = [];
        if (model.paymentType === 'PREPAID') {
            const mtBundle = this.model.bundles.find(b => b.serviceType === 'MT');
            if (!mtBundle) {
                this.notificationService.error('Pricing group', 'MT bundle is required.');
                return;
            }
            model.bundles = this.model.bundles.map(b => {
                if (b.serviceType === 'CLI') {
                    b.serviceType = 'VOICE';
                }
                if (b.serviceType !== 'MT') {
                    b.commitment = 0;
                    b.visibleFrom = mtBundle?.visibleFrom;
                    b.visibleUntil = mtBundle?.visibleUntil;
                    b.validForSeconds = mtBundle?.validForSeconds;
                }
                return b;
            });
        } else {
            model.tiers = this.model.tiers.map(t => {
                if (t.serviceType === 'CLI') {
                    t.serviceType = 'VOICE';
                }
                return t;
            });
            model.tiers = this.model.tiers;
        }
        model.analyticsTariffs.forEach(at => {
            for (let i in at) { if (!at[i]) { delete at[i]; } }
        });
        model = CustomUtils.trimModelFields(model);
        this.saveSpecialGroup(model);
    }

    saveSpecialGroup(model: SpecialPricingGroup): void {
        this.service.saveSpecialGroup(model)
            .subscribe({
                next: (res: boolean) => {
                    this.loading = false;
                    this.onAfterSave.emit();
                    this.notificationService.success(
                        'Pricing group ' + (this.model.id ? 'updated' : 'created'),
                        'Special pricing'
                    );
                },
                error: error => {
                    this.loading = false;
                    this.notificationService.error({
                        title: 'Special pricing',
                        message: 'An error occurred while creating/updating the pricing group',
                        serviceName: 'GATEWAY',
                        requestMessage: error.statusText,
                        requestCode: error.status,
                        ts: error.timestamp ? error.timestamp : null
                    });
                }
            });
    }

    updatePackagesCount() {
        let count = 0;
        if (this.model.paymentType === 'PREPAID') {
            count = this.model.bundles.filter(_ => !_.hidden).length;
        } else {
            count = this.model.tiers.filter(_ => !_.hidden).length;
        }

        this.packagesCount = count;
        this.form.controls.packagesCount.patchValue(this.packagesCount);
    }

    onChangePaymentType() {
        this.model.users = [];
    }

    getMinDate() {
        const tomorrow = new Date();
        tomorrow.setDate(tomorrow.getDate() + 1);
        return tomorrow;
    }

}
