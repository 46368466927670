import { Inject, Injectable } from "@angular/core";
import { NavigationEnd, Router } from '@angular/router';
import { datadogRum } from '@datadog/browser-rum';
import { HttpErrors } from './http-errors';
import { UsersService } from './users.service';

@Injectable()
export class DatadogService {

    userInit = false;
    releaseVersion = "";

    constructor(
        public router: Router,
        public userService: UsersService,
        @Inject('RELEASE_VERSION') releaseVersion: string
    ) {
        this.releaseVersion = releaseVersion
    }

    init() {
        datadogRum.init({
            applicationId: 'c8d14247-7af2-40cd-9509-4ce98e62d273',
            clientToken: 'pubbb89fb8e25e2d64b0d6e86fde3f214f5',
            site: 'datadoghq.eu',
            service: 'telq-testing-platform',
            env: this.getEnv(),
            version: this.releaseVersion,
            sessionSampleRate: 100,
            sessionReplaySampleRate: 0,
            trackResources: true,
            beforeSend: (event) => {
                // Filter RESOURCE events based on the URL's host
                if (event.type === 'resource') {
                  const allowedHosts = [
                      'api.telqtele.com',
                      'api-proxy.telqtele.com',
                      'api.dev.telqtele.com',
                      'api-proxy.dev.telqtele.com',
                  ];
                  const resourceUrl = new URL(event.resource.url);

                  // Drop the event if the host is not in the allowed list
                  if (!allowedHosts.includes(resourceUrl.host)) {
                    return false; // Prevent the event from being sent
                  }
                }
                // Allow all other events
                return true;
              },
            //trackViewsManually: true
        });

        this.userService.authUpdates.subscribe(user => {
            //datadogRum.startSessionReplayRecording();
            datadogRum.setUser({
                id: String(user.id),
                email: user.email,
                name: user.fullName,
                role: user.role,
                paymentType: user.paymentType,
                impersonated: user.impersonated ? 'true' : 'false'
            });
            this.userInit = true;
        });

        this.router.events.subscribe(e => {
            if (e instanceof NavigationEnd) {
                datadogRum.startView(e.url)
            }
        })

        HttpErrors.status.subscribe(status => {
            if (status === 401 && this.userInit) {
                datadogRum.removeUser();
                this.userInit = false;
                //datadogRum.stopSessionReplayRecording();
            }
        });
    }

    getEnv(): string {
        const host = location.hostname.toLowerCase();
        if (host.startsWith('localhost')) {
            return 'local';
        } else if (host.startsWith('app.dev')) {
            return 'dev';
        } else if (host.startsWith('app.tp-')) {
            return host.split('.')[1];
        } else if (host.startsWith('app')) {
            return 'prod';
        } else {
            return host;
        }
    }
}
