import {
    AfterViewInit,
    Component,
    EventEmitter,
    Output,
    ViewChild
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ValidatorFn } from '@angular/forms';
import { Router } from '@angular/router';
import { InputDateTime } from '../../shared/components/input/input.dateTime';
import { InputDuration } from '../../shared/components/input/input.duration';
import { InputSearchDatePeriod } from '../../shared/components/input/input.search.datePeriod';
import { ScheduledTask } from '../../shared/models/scheduled-task.model';
import { LIVE_NUMBER_TEST_STATUSES, MESSAGE_STATES, getItemLabel } from '../../shared/models/test-group.model';
import { CustomUtils } from '../../shared/services/custom-utils';
import { DialogRef, ModalService } from "../../shared/services/modal.service";
import { NotificationService } from '../../shared/services/notification.service';
import { CombinationRuleItem, SchedulerService, StatusItem, TaskScheduleDef } from '../../shared/services/scheduler.service';
import { UsersService } from '../../shared/services/users.service';
import { ValidationService, Validators as Vld } from '../../shared/services/validation.service';
import { LntFormComponent } from '../../test/lnt/lnt-form/lnt-form.component';
import { animate, style, transition, trigger } from '@angular/animations';
declare var moment: any;

@Component({
    selector: 'app-scheduler-form',
    templateUrl: './scheduler-form.component.html',
    styleUrls: ['./scheduler-form.component.scss'],
    animations: [
        trigger('fieldAnimation', [
            transition(':enter', [
                style({ opacity: 0, transform: 'translateY(-20px)' }),
                animate('300ms ease-out', style({ opacity: 1, transform: 'translateY(0)' }))
            ]),
            transition(':leave', [
                animate('300ms ease-in', style({ opacity: 0, transform: 'translateY(-20px)' }))
            ])
        ])
    ]
})

export class SchedulerFormComponent implements AfterViewInit {

    @Output() onScheduleAfterSave = new EventEmitter();
    @Output() onLoad = new EventEmitter();

    @ViewChild('repeatEveryControl', { read: InputDuration, static: false }) repeatEveryControl: InputDuration;

    @ViewChild(LntFormComponent, { static: false }) tests: LntFormComponent;
    @ViewChild(InputDateTime, { static: false }) startAtControl: InputDateTime;
    @ViewChild(InputSearchDatePeriod, { static: false }) datePeriodControl: InputSearchDatePeriod;

    loading = false;
    testsCount = 0;
    minDateScheduler: Date = new Date(Date.now() + 1 * 60 * 1000);
    deviceTimezone: string = '';

    model: ScheduledTask = {
        title: '',
        description: '',
        sendOnlyOnce: false,
        startAt: null,
        finishAt: null,
        fromHour: '00:00',
        tillHour: '23:59',
        daysOfWeek: [],
        repeatEvery: 60,
        reportEmails: [],
        userId: null,
        reportPerAlertEnabled: true,
        repetitionReportPerAlertEnabled: false,
        allAlertXlsReportEnabled: false,
        repetitionAlertXlsReportEnabled: false,
        allTestXlsReportEnabled: false,
        runUntilDisabled: false,
        randomized: false,
        testRepeatsPerIteration: 1,
        repeatsPerDayRandomized: 1,
        callbackUrl: ''
    };

    datePeriodRanges = {
        'Today': [moment().set({ hour: 0, minute: 0 }), moment().endOf('day').set({ hour: 23, minute: 59 })],
        'Tomorrow': [moment().add(1, 'days').set({ hour: 0, minute: 0 }), moment().add(1, 'days').set({ hour: 23, minute: 59 })],
        'Next 7 Days': [moment().set({ hour: 0, minute: 0 }), moment().add(6, 'days').set({ hour: 23, minute: 59 })],
        'Next 30 Days': [moment().set({ hour: 0, minute: 0 }), moment().add(29, 'days').set({ hour: 23, minute: 59 })],
        'This Month': [moment().set({ hour: 0, minute: 0 }), moment().endOf('month').set({ hour: 23, minute: 59 })]
    };

    days: { selected: boolean, label: string, value: string }[] = [];

    reportEmails: string[] = [];

    reportsFinalMessageStates = ['DELIVERED', 'UNDELIVERABLE', 'REJECTED', 'EXPIRED', 'NO_DLR_RECEIVED', 'SUBMIT_SM_FAILED'];
    reportsFinalTestStatuses = ['NOT_DELIVERED', 'POSITIVE', 'TEST_NUMBER_OFFLINE', 'TEXT_REPLACED', 'SENDER_REPLACED', 'NETWORK_OFFLINE'];

    reportsIntermediateMessageStates = ['PENDING', 'ACCEPTED', 'ENROUTE', 'PARTIALLY_DELIVERED', 'SCHEDULED'];
    reportsIntermediateTestStatuses = ['WAIT', 'TEST_NUMBER_NOT_AVAILABLE'];

    reportRules = {
        finalMessageStates: [],
        finalTestStatuses: [],
        intermediateMessageStates: [],
        intermediateTestStatuses: [],
        combinationMessageStates: [],
        combinationTestStatuses: [],
        combinationRules: []
    };

    combinationRuleIndex = 0;
    combinationRule = {
        id: null,
        messageStateShow: true,
        messageState: {
            value: null,
            isFinal: null,
            wait: 0
        },
        testStatusShow: true,
        testStatus: {
            value: null,
            isFinal: null,
            wait: 0
        },
    };

    taskForm: FormGroup;
    onlyOnceForm: FormGroup;
    randomizedTask: FormGroup;
    periodForm: FormGroup;
    reportsForm: FormGroup;
    formEmail: FormGroup;
    startAtValidators: ValidatorFn;
    scheduleHistory: TaskScheduleDef;
    emailsModal: DialogRef;
    combinationRuleModal: DialogRef;
    showEmailField: boolean = false;
    showCallbackUrlField: boolean = false;
    currentStep: number = 1;
    repetitionsTarget: number = 1;

    constructor(
        public router: Router,
        public notificationService: NotificationService,
        formBuilder: FormBuilder,
        public validationService: ValidationService,
        public schedulerService: SchedulerService,
        public userService: UsersService,
        public modal: ModalService,
    ) {
        this.validationService.addFormatter('allowedRandomizedTasksPerDay',
            this.validationService.createTextFormatter(
                `Please, adjust the time range (Run tests only between) or number of repeats.  
            It is not allowed to start task with frequency higher than 1 test per minute. 
            Max number of repeats can be calculated as the amount of minutes within the chosen range 
            (for example, number of repeats per day cannot exceed 1439 for the range between 00:00 - 23:59).`)
        );

        let randomizedTaskValidator = form => {
            const repeats = form.get("repeatsPerDayRandomized").value;
            const fromHoursMinutes = form.get("fromHour").value;
            const tillHoursMinutes = form.get("tillHour").value;
            const execeeded = this.exceededNumberOfRepeatsForRandomizedTask(repeats, fromHoursMinutes, tillHoursMinutes);
            return execeeded ? { 'allowedRandomizedTasksPerDay': true } : null;
        }

        let tillHourValidator = (group: FormGroup) => {
            const fromHour = group.get('fromHour').value;
            const tillHour = group.get('tillHour').value;
            const differenceInMinutes = this.getFromTillMinutesDifference(fromHour, tillHour);
            if (differenceInMinutes < 10) {
                return { tillHourInvalid: true };
            }
            return null;
        }

        this.taskForm = formBuilder.group({
            title: ['', Vld.compose([Vld.required, Vld.minLength(3), Vld.maxLength(255)])],
            description: ['', Vld.compose([Vld.maxLength(255)])],
            sendOnlyOnce: [false],
            runUntilDisabled: [false],
            randomized: [false]
        });

        this.deviceTimezone = CustomUtils.getTimezone();

        this.onlyOnceForm = formBuilder.group({
            startAt: ['', Vld.compose([Vld.required])],
        });

        this.startAtValidators = Vld.compose([Vld.required]);

        this.periodForm = formBuilder.group({
            startAt: ['', Vld.compose([Vld.required])],
            finishAt: ['', Vld.compose([Vld.required])],
            fromHour: ['', Vld.compose([Vld.hourFormat24()])],
            tillHour: ['', Vld.compose([Vld.hourFormat24()])],
            daysOfWeek: ['', Vld.compose([Vld.required, Vld.integer(), Vld.min(0), Vld.max(7)])],
            repeatEvery: ['', Vld.compose([Vld.required, Vld.integer(), Vld.min(1)])],
            repeatsPerDayRandomized: [this.model.repeatsPerDayRandomized, Vld.compose([Vld.required, Vld.min(1), Vld.integer()])]
        }, { validator: [randomizedTaskValidator, tillHourValidator] });
        this.days = SchedulerService.DAYS.map(_ => {
            return { selected: true, label: _.label, value: _.value };
        });
        this.model.daysOfWeek = this.days.filter(_ => _.selected).map(_ => _.value);
        this.periodForm.controls.daysOfWeek.patchValue(this.model.daysOfWeek.length);

        this.reportsForm = formBuilder.group({
            reportEmails: ['', Vld.compose([Vld.required])],
            reportsCount: [''],
            reportPerAlertEnabled: [''],
            repetitionReportPerAlertEnabled: [''],
            allAlertXlsReportEnabled: [''],
            repetitionAlertXlsReportEnabled: [''],
            allTestXlsReportEnabled: [''],
            callbackUrl: ['', Vld.compose([Vld.url(true)])]
        });
        this.formEmail = formBuilder.group({
            email: ['', Vld.compose([Vld.email(true), Vld.maxLength(254)])],
        });
        this.reportRules.finalMessageStates = this.reportsFinalMessageStates.map(_ => {
            return { label: getItemLabel(MESSAGE_STATES, _), selected: false, id: _ }
        });
        this.reportRules.finalTestStatuses = this.reportsFinalTestStatuses.map(_ => {
            return { label: getItemLabel(LIVE_NUMBER_TEST_STATUSES, _), selected: false, id: _ }
        });
        this.reportRules.intermediateMessageStates = this.reportsIntermediateMessageStates.map(_ => {
            return { label: getItemLabel(MESSAGE_STATES, _), selected: false, id: _ }
        });
        this.reportRules.intermediateTestStatuses = this.reportsIntermediateTestStatuses.map(_ => {
            return { label: getItemLabel(LIVE_NUMBER_TEST_STATUSES, _), selected: false, id: _ }
        });

        this.reportRules.combinationMessageStates = MESSAGE_STATES.map(_ => {
            const isFinal = this.reportsIntermediateMessageStates.indexOf(_.id) === -1;
            return { label: _.label, isFinal: isFinal, id: _.id }
        });
        this.reportRules.combinationTestStatuses = LIVE_NUMBER_TEST_STATUSES.map(_ => {
            const isFinal = this.reportsIntermediateTestStatuses.indexOf(_.id) === -1;
            return { label: _.label, isFinal: isFinal, id: _.id }
        });
    }

    getFromTillMinutesDifference(fromHours: number | string, tillHours: number | string): number {
        const fromHoursMinutes = fromHours.toString().split(':').map(_ => _.padStart(2, '0'));
        const tillHoursMinutes = tillHours.toString().split(':').map(_ => _.padStart(2, '0'));
        const fromMinutes = (parseInt(fromHoursMinutes[0]) * 60 + (fromHoursMinutes.length === 1 ? 0 : parseInt(fromHoursMinutes[1])));
        const tillMinutes = (parseInt(tillHoursMinutes[0]) * 60 + (tillHoursMinutes.length === 1 ? 0 : parseInt(tillHoursMinutes[1])));
        return tillMinutes - fromMinutes >= 0 ? tillMinutes - fromMinutes : 0;
    }

    exceededNumberOfRepeatsForRandomizedTask(repeats: number, fromHours: number | string, tillHours: number | string): boolean {
        const difference = this.getFromTillMinutesDifference(fromHours, tillHours);
        return repeats > difference;
    }

    getRandomizedIntervalInMinutes() {
        return (this.getFromTillMinutesDifference(this.model.fromHour, this.model.tillHour) / this.model.repeatsPerDayRandomized).toFixed(2)
    }

    get testsPerBatch(): number | string {
        const countTests = Number(this.scheduleHistory.countTests);
        const countTasks = Number(this.scheduleHistory.countTasks);
        if (isNaN(countTests) || isNaN(countTasks) || countTasks === 0) {
            return 'N/A';
        }
        return countTests / countTasks;
    }

    get countTestsNumber(): number {
        return Number(this.scheduleHistory.countTests);
    }

    ngAfterViewInit() {
        this.userService.getAuthUser().then(_ => {
            if (_.email) {
                this.model.reportEmails.push(_.email);
                this.updateEmails();
            }
        });
        this.model.zoneOffsetSec = this.getTZOffset();
    }

    onLoadTestsForm() {
        this.onLoad.emit();
    }

    onStartAtChange(value) {
        if (value instanceof Event) {
            return;
        }
        this.model.startAt = value;
        if (this.model.runUntilDisabled) {
            const after200YearsUnixTimeStamp = moment().add(200, 'years').unix();
            this.model.finishAt = after200YearsUnixTimeStamp;
            this.periodForm.get('startAt').patchValue(value);
            this.periodForm.get('finishAt').patchValue(after200YearsUnixTimeStamp);
        }
        this.onlyOnceForm.get('startAt').patchValue(value);
        this.updateHistory();
    }

    onChangeCountResults(count) {
        this.testsCount = count;
        this.updateHistory();
    }

    onChangeDatePeriod(dates) {
        if (dates instanceof Event || typeof dates === 'string') {
            return;
        }
        this.periodForm.controls.startAt.patchValue(dates.start);
        this.periodForm.controls.finishAt.patchValue(dates.end);
        this.model.startAt = dates.start;
        this.model.finishAt = dates.end;
        this.updateHistory();
    }

    onChangeSendOnlyOnce() {
        if (!this.startAtControl) {
            return;
        }

        this.model.startAt = null;
        this.model.finishAt = null;
        this.model.runUntilDisabled = false;
        this.model.randomized = false;
        this.periodForm.controls.startAt.patchValue('');
        this.periodForm.controls.finishAt.patchValue('');
        this.onlyOnceForm.controls.startAt.patchValue('');
        this.startAtControl.model = '';
        this.startAtControl.onClickClear();
        if (this.datePeriodControl) {
            this.datePeriodControl.model = '';
            this.datePeriodControl.reset();
        }

        this.updateHistory();
    }

    onChangerunUntilDisabled() {
        this.model.startAt = null;
        this.model.finishAt = null;
        this.periodForm.controls.startAt.patchValue('');
        this.periodForm.controls.finishAt.patchValue('');
        this.onlyOnceForm.controls.startAt.patchValue('');
        if (this.startAtControl)
            this.startAtControl.model = '';
        this.updateHistory();
    }

    onChangeRandomized() {
        if (!this.model.randomized) {
            this.model.repeatsPerDayRandomized = 10;
            const newValue = SchedulerService.secondsToHuman(this.model.repeatEvery);
            this.repeatEveryControl?.setUnit(newValue.unit);
            this.repeatEveryControl?.setValue(newValue.value);
            this.periodForm.controls.repeatsPerDayRandomized.patchValue(10);
        }
        this.updateHistory();
    }

    onChangeRepeatsPerDay() {
        this.updateHistory();
    }

    onChangeRepeatEvery(e) {
        if (e instanceof Event) {
            return;
        }
        const newValue = SchedulerService.repeatEveryToSeconds(e.value, e.unit);
        if (this.model.repeatEvery !== newValue) {
            this.model.repeatEvery = newValue;
            this.periodForm.controls.repeatEvery.patchValue(this.model.repeatEvery);
            this.updateHistory();
        }
    }

    updateHour(value) {
        if (value.length === 5) {
            this.updateHistory();
        }
    }

    updateHistory() {
        this.scheduleHistory = SchedulerService.calculateSchedule(this.model, this.testsCount);
    }

    toggleDay(day) {
        day.selected = !day.selected;
        this.model.daysOfWeek = this.days.filter(_ => _.selected).map(_ => _.value);
        this.periodForm.controls.daysOfWeek.patchValue(this.model.daysOfWeek.length);
        this.updateHistory();
    }

    validTab(stepNumber: number = 0): boolean {
        if (!this.tests) {
            return false;
        }
        if (stepNumber === 0) {
            return this.validTab(1) && this.validTab(2) && this.validTab(3)
        }
        if (stepNumber === 1) {
            return this.tests.form.valid && this.testsCount > 0;
        }
        if (stepNumber === 2) {
            if (this.model.sendOnlyOnce) {
                return this.taskForm.valid && this.onlyOnceForm.valid;
            } else if (this.model.runUntilDisabled) {
                return this.taskForm.valid && this.onlyOnceForm.valid && this.periodForm.valid && this.scheduleHistory && Number(this.scheduleHistory.countTests) !== 0;
            }
            return this.taskForm.valid && this.periodForm.valid && this.scheduleHistory && Number(this.scheduleHistory.countTests) > 0;
        }
        if (stepNumber === 3) {
            return this.reportsForm.valid && this.formEmail.valid;
        }
        return false;
    }


    onSubmitEmailForm() {
        const emailFormControl: FormControl = this.formEmail.get('email') as FormControl;
        const email = emailFormControl.value;
        const isEmailAlreadyAddded = this.model.reportEmails.indexOf(email) !== -1;
        if (isEmailAlreadyAddded) {
            this.notificationService.error('Email already added', 'Scheduler');
            return;
        }
        if (this.model.reportEmails.length >= 10) {
            this.notificationService.error('You can only add up to 10 emails', 'Scheduler');
            return;
        }
        if (email && this.formEmail.valid) {
            this.model.reportEmails.push(email);
            this.updateEmails();
            emailFormControl.patchValue('');
        }
        if (this.emailsModal) {
            this.emailsModal.close(true);
        }
    }

    onUnSelectEmail(email) {
        this.model.reportEmails = this.model.reportEmails.filter(_ => _ !== email);
        this.updateEmails();
    }

    updateEmails() {
        let str = this.model.reportEmails.join(' ');
        this.reportsForm.controls.reportEmails.patchValue(str);
    }

    onChangeCombinationRuleTestStatusValue() {
        const status = this.reportRules.combinationTestStatuses.filter(_ => _.id === this.combinationRule.testStatus.value)[0];
        this.combinationRule.testStatus.isFinal = status.isFinal;
    }

    onChangeCombinationRuleMessageStateValue() {
        const status = this.reportRules.combinationMessageStates.filter(_ => _.id === this.combinationRule.messageState.value)[0];
        this.combinationRule.messageState.isFinal = status.isFinal;
    }

    onChangeStatusWait(e: Event | { value: number, unit: string }, status: StatusItem) {
        if (e instanceof Event || isNaN(e.value)) {
            return;
        }
        status.wait = SchedulerService.repeatEveryToSeconds(e.value, e.unit);
    }

    formatMessageState(id) {
        return getItemLabel(MESSAGE_STATES, id);
    }

    formatTestStatus(id) {
        return getItemLabel(LIVE_NUMBER_TEST_STATUSES, id);
    }

    formatSeconds(seconds) {
        let t = SchedulerService.secondsToHuman(seconds);
        return t.value.toFixed(2) + t.unit;
    }

    getTZOffset() {
        return -((new Date()).getTimezoneOffset()) * 60;
    }

    onClickSave() {
        this.loading = true;
        if (this.model.callbackUrl && !this.model.callbackUrl.startsWith('http')) {
            this.model.callbackUrl = `https://${this.model.callbackUrl}`;
            this.reportsForm.controls.callbackUrl.setValue(this.model.callbackUrl);
        }
        this.tests.saveTestCaseTemplate('auto', true).subscribe({
            next: (t) => {
                let model = {
                    ...this.model,
                    callbackUrl: this.model.callbackUrl || null,
                    startAt: moment.unix(<number>this.model.startAt - this.model.zoneOffsetSec).format('YYYY-MM-DDTHH:mm:ss') + '.000Z',
                    finishAt: moment.unix(<number>this.model.finishAt - this.model.zoneOffsetSec).format('YYYY-MM-DDTHH:mm:ss') + '.000Z',
                    testCaseTemplateId: t.id,
                    reportRuleDtos: this.reportRules.combinationRules
                        .filter(_ => _.messageState || _.testStatus)
                        .map(_ => ({
                            testStatus: _.testStatus,
                            messageState: _.messageState,
                            testStatusWaitingTime: _.testStatusWait,
                            messageStateWaitingTime: _.messageStateWait,
                            repetitionsTarget: _.repetitionsTarget,
                            isConsecutive: _.isConsecutive
                        })),
                    testRepeatsPerIteration: this.tests.model.testRepeatsPerIteration,
                    title: this.model.title.trim(),
                    description: this.model.description?.trim() || null,
                    fromHour: (typeof this.model.fromHour === 'string' && this.model.fromHour.length)
                        ? +this.model.fromHour.replace(':', '.')
                        : this.model.fromHour,
                    tillHour: (typeof this.model.tillHour === 'string' && this.model.tillHour.length)
                        ? +this.model.tillHour.replace(':', '.')
                        : this.model.tillHour
                };

                this.schedulerService.save(model, true).subscribe({
                    next: () => {
                        this.loading = false;
                        this.notificationService.success('Task created', 'Scheduler');
                        this.onScheduleAfterSave.emit(true);
                    },
                    error: (error) => this.handleTaskCreationError(error)
                });
            },
            error: (error) => this.handleTaskCreationError(error)
        });
    }

    private handleTaskCreationError(error: any) {
        this.loading = false;
        this.notificationService.error(`Task has not been created due to an error: ${error.statusText}`, 'Scheduler');
    }

    setModel(task: ScheduledTask) {
        if (task.testCaseTemplateId) {
            const testRepeatsPerIteration = task.testRepeatsPerIteration ? task.testRepeatsPerIteration : 1;
            this.tests.setTemplateTestCaseId(task.testCaseTemplateId, false, testRepeatsPerIteration);
        }
        this.model.title = task.title;
        this.taskForm.controls.title.patchValue(this.model.title);

        this.model.description = task.description;
        this.taskForm.controls.description.patchValue(this.model.description);

        this.model.runUntilDisabled = task.runUntilDisabled;
        this.taskForm.controls.runUntilDisabled.patchValue(this.model.runUntilDisabled);

        if (!task.sendOnlyOnce) {
            this.model.daysOfWeek = task.daysOfWeek;
            this.days.map(_ => {
                _.selected = this.model.daysOfWeek.indexOf(_.value) !== -1;
            });
            this.periodForm.controls.daysOfWeek.patchValue(this.model.daysOfWeek.length);

            if (typeof task.fromHour !== "string") {
                this.model.fromHour = ('' + task.fromHour.toFixed(2)).replace('.', ':');
                if (this.model.fromHour.length === 4) {
                    // For eg: '9:00' should be shown as '09:00' ...
                    this.model.fromHour = '0' + this.model.fromHour;
                }
            }

            if (task.fromHour + '' === '0') {
                this.model.fromHour = '00:00';
            }
            this.periodForm.controls.fromHour.patchValue(this.model.fromHour);

            if (typeof task.tillHour !== "string") {
                this.model.tillHour = ('' + task.tillHour.toFixed(2)).replace('.', ':');
                if (this.model.tillHour.length === 4) {
                    this.model.tillHour = '0' + this.model.tillHour;
                }
            }

            if (task.tillHour + '' === '0') {
                this.model.tillHour = '00:00';
            }
            this.periodForm.controls.tillHour.patchValue(this.model.tillHour);

            const repeatEvery = SchedulerService.secondsToHuman(task.repeatEvery);
            this.repeatEveryControl.modelUnit = repeatEvery.unit;
            this.repeatEveryControl.modelValue = repeatEvery.value;
            this.model.repeatEvery = task.randomized ? task.repeatsPerDayRandomized * 60 : task.repeatEvery;
            this.model.randomized = task.randomized;
            this.model.repeatsPerDayRandomized = task.repeatsPerDayRandomized;
            this.periodForm.controls.repeatEvery.patchValue(this.model.repeatEvery);

            const isEndDateInFuture = moment().isBefore(moment(task.finishAt));
            if (isEndDateInFuture) {
                // Only populate if the end date is in the future ... Make user to enter date again which always will be in future ...
                if (typeof task.startAt === "string") {
                    this.datePeriodControl.start = Date.parse(task.startAt) / 1000;
                }
                if (typeof task.finishAt === "string") {
                    this.datePeriodControl.end = Date.parse(task.finishAt) / 1000;
                }
                this.datePeriodControl.updateValue();
                setTimeout(() => {
                    if (typeof task.startAt === "string") {
                        this.model.startAt = Date.parse(task.startAt) / 1000;
                    }
                    if (typeof task.finishAt === "string") {
                        this.model.finishAt = Date.parse(task.finishAt) / 1000;
                    }
                    this.periodForm.controls.startAt.patchValue(this.model.startAt);
                    this.periodForm.controls.finishAt.patchValue(this.model.finishAt);
                    if (typeof task.startAt === "string") {
                        this.startAtControl.value = Date.parse(task.startAt) / 1000;
                    }
                    this.startAtControl.updateValue();
                    this.onlyOnceForm.get('startAt').setValue(this.model.startAt);
                    this.periodForm.get('startAt').setValue(this.model.startAt);
                    this.periodForm.get('finishAt').setValue(this.model.finishAt);
                }, 0)
            } 
        } else {
            this.model.sendOnlyOnce = task.sendOnlyOnce;
            this.taskForm.controls.sendOnlyOnce.patchValue(this.model.sendOnlyOnce);

            const isStartDateInFuture = moment().isBefore(moment(task.startAt));
            // When user clones, if start date is in the future. Make user to enter date again which always will be in future ... 
            if (isStartDateInFuture) {
                if (typeof task.startAt === "string") {
                    this.startAtControl.value = Date.parse(task.startAt) / 1000;
                }
                this.startAtControl.updateValue();
                if (typeof task.startAt === "string") {
                    this.model.startAt = Date.parse(task.startAt) / 1000;
                }
                this.onlyOnceForm.controls.startAt.patchValue(this.model.startAt);
            }
        }

        this.updateHistory();

        if (task.reportEmails.length) {
            this.model.reportEmails = task.reportEmails;
            this.reportEmails = task.reportEmails;
        }

        if (task.reportRuleDtos.length) {
            task.reportRuleDtos.map(_ => {
                this.combinationRuleIndex++;
                this.reportRules.combinationRules.push({
                    id: this.combinationRuleIndex,
                    messageState: _.messageState ? _.messageState : null,
                    messageStateWait: _.messageStateWaitingTime ? _.messageStateWaitingTime : null,
                    testStatus: _.testStatus ? _.testStatus : null,
                    testStatusWait: _.testStatusWaitingTime ? _.testStatusWaitingTime : null,
                    isConsecutive: _.isConsecutive ? _.isConsecutive : false,
                    repetitionsTarget: _.repetitionsTarget
                });
                this.reportsForm.controls.reportsCount.patchValue(this.reportRules.combinationRules.length);
            });
        }

        if (task.callbackUrl) {
            this.model.callbackUrl = task.callbackUrl;
            this.reportsForm.controls.callbackUrl.patchValue(task.callbackUrl);
        }

        this.model.reportPerAlertEnabled = task.reportPerAlertEnabled;
        this.model.repetitionReportPerAlertEnabled = task.repetitionReportPerAlertEnabled;
        this.model.allAlertXlsReportEnabled = task.allAlertXlsReportEnabled;
        this.model.repetitionAlertXlsReportEnabled = task.repetitionAlertXlsReportEnabled;
        this.model.allTestXlsReportEnabled = task.allTestXlsReportEnabled;
        this.model.callbackUrl = task.callbackUrl;
        this.showCallbackUrlField = this.model.callbackUrl ? true : false;
    }

    removeClickedMessageRule(rule: CombinationRuleItem) {
        const newRule = {
            ...rule, messageState: null, messageStateWait: null
        }
        if (this.doesRuleAlreadyExist(newRule)) {
            this.notificationService.error('This rule cannot be removed. The removed rule already exists.', 'Duplicate Rule');
            return;
        }
        this.reportRules.finalMessageStates.map(_ => {
            if (_.id === rule.messageState) {
                _.selected = false;
            }
        });
        rule.messageState = null;
        rule.messageStateWait = null;
    }

    removeClickedTestRule(rule: CombinationRuleItem) {
        const newRule = {
            ...rule, testStatus: null, testStatusWait: null
        }
        if (this.doesRuleAlreadyExist(newRule)) {
            this.notificationService.error('This rule cannot be removed. The removed rule already exists.', 'Duplicate Rule');
            return;
        }
        this.reportRules.finalTestStatuses.map(_ => {
            if (_.id === rule.testStatus) {
                _.selected = false;
            }
        });
        rule.testStatus = null;
        rule.testStatusWait = null;
    }

    addStatus(addStatusTo: string, status: StatusItem) {
        const rule = {
            id: this.combinationRuleIndex,
            messageState: null,
            messageStateWait: null,
            testStatus: null,
            testStatusWait: null,
            isConsecutive: false,
            repetitionsTarget: 1
        };
        if (addStatusTo === 'test') {
            rule.testStatus = status.id;
            rule.testStatusWait = status.wait;
        } else {
            rule.messageState = status.id;
            rule.messageStateWait = status.wait;
        }
        this.addCombinationRule(rule);
    }

    onApplyStatuses(): void {
        const selectedFinalTestStatuses = this.reportRules.finalTestStatuses.filter(_ => _.selected);
        selectedFinalTestStatuses.forEach(_ => {
            this.addStatus('test', _);
        });
        const selectedIntermediateTestStatuses = this.reportRules.intermediateTestStatuses.filter(_ => _.selected);
        selectedIntermediateTestStatuses.forEach(_ => {
            this.addStatus('test', _);
        })
        const selectedFinalMessageStatuses = this.reportRules.finalMessageStates.filter(_ => _.selected);
        selectedFinalMessageStatuses.forEach(_ => {
            this.addStatus('message', _);
        })
        const selectedIntermediateMessageStates = this.reportRules.intermediateMessageStates.filter(_ => _.selected);
        selectedIntermediateMessageStates.forEach(_ => {
            this.addStatus('message', _);
        });
        this.unselectAllStatuses();
    }

    unselectAllStatuses(): void {
        this.reportRules.finalTestStatuses.forEach(_ => _.selected = false);
        this.reportRules.intermediateTestStatuses.forEach(_ => _.selected = false);
        this.reportRules.finalMessageStates.forEach(_ => _.selected = false);
        this.reportRules.intermediateMessageStates.forEach(_ => _.selected = false);
    }

    clearAllStatuses(): void {
        this.reportRules.combinationRules.forEach(_ => {
            this.removeCombinationReportRule(_.id);
        });
        this.unselectAllStatuses();
    }

    isReceiptStatusesDisabled(): boolean {
        return this.reportRules.finalMessageStates.some(_ => _.selected) || this.reportRules.intermediateMessageStates.some(_ => _.selected);
    }

    isTestStatusesDisabled(): boolean {
        return this.reportRules.finalTestStatuses.some(_ => _.selected) || this.reportRules.intermediateTestStatuses.some(_ => _.selected);
    }

    doesRuleAlreadyExist(rule: CombinationRuleItem): boolean {
        const properties = ['messageState', 'messageStateWait', 'testStatus', 'testStatusWait', 'repetitionsTarget'];
        return this.reportRules.combinationRules.filter(_ => {
            let result = true;
            properties.map(prop => {
                _[prop] = _[prop] ?? null;
                rule[prop] = rule[prop] ?? null;
                if (result && _[prop] !== rule[prop]) {
                    result = false;
                }
            });
            return result;
        }).length > 0;
    }

    addCombinationRule(rule: CombinationRuleItem) {
        if (this.doesRuleAlreadyExist(rule)) {
            this.notificationService.error('You are already using this rule. Hence it has been ignored.', 'Duplicate Rule');
            return;
        }
        this.combinationRuleIndex++;
        rule.id = this.combinationRuleIndex;
        this.reportRules.combinationRules.push(rule);
        this.reportsForm.controls.reportsCount.patchValue(this.reportRules.combinationRules.length);
    }

    handleReceiptCheckbox(selectedStatus: StatusItem): void {
        this.reportRules.finalTestStatuses.forEach(status => {
            status.selected = false;
        });
        this.reportRules.intermediateTestStatuses.forEach(status => {
            status.selected = false;
        });
        selectedStatus.selected = true;
    }


    handleDlrCheckbox(selectedStatus: StatusItem): void {
        this.reportRules.finalMessageStates.forEach(status => {
            status.selected = false;
        });
        this.reportRules.intermediateMessageStates.forEach(status => {
            status.selected = false;
        });
        selectedStatus.selected = true;
    }

    saveDlrStatus(rule: CombinationRuleItem): void {
        let status = this.reportRules.finalMessageStates.find(_ => _.selected);
        if (!status) {
            status = this.reportRules.intermediateMessageStates.find(_ => _.selected);
        }
        const newRule = {
            id: this.combinationRuleIndex,
            messageState: status.id,
            messageStateWait: status.wait,
            testStatus: rule.testStatus,
            testStatusWait: rule.testStatusWait,
            isConsecutive: rule.isConsecutive,
            repetitionsTarget: rule.repetitionsTarget
        }
        if (this.doesRuleAlreadyExist(newRule)) {
            this.notificationService.error('You are already using this rule. Hence it has been ignored.', 'Duplicate Rule');
            return;
        }
        rule.messageState = status.id;
        rule.messageStateWait = status.wait;
        rule.messageStatusDropdownShow = false;
        this.unselectAllStatuses();
    }

    saveRecieptStatus(rule: CombinationRuleItem): void {
        let status = this.reportRules.finalTestStatuses.find(_ => _.selected);
        if (!status) {
            status = this.reportRules.intermediateTestStatuses.find(_ => _.selected);
        }
        const newRule = {
            id: this.combinationRuleIndex,
            messageState: rule.messageState,
            messageStateWait: rule.messageStateWait,
            testStatus: status.id,
            testStatusWait: status.wait,
            repetitionsTarget: rule.repetitionsTarget,
            isConsecutive: rule.isConsecutive
        }
        if (this.doesRuleAlreadyExist(newRule)) {
            this.notificationService.error('You are already using this rule. Hence it has been ignored.', 'Duplicate Rule');
            return;
        }
        rule.testStatus = status.id;
        rule.testStatusWait = status.wait;
        rule.testStatusDropdownShow = false;
        this.unselectAllStatuses();
    }

    removeCombinationReportRule(id) {
        this.reportRules.combinationRules = this.reportRules.combinationRules.filter(_ => _.id !== id);
        this.reportsForm.controls.reportsCount.patchValue(this.reportRules.combinationRules.length);
    }

    getProgressWidth(): void {
        const stepPercentage = 33.33;
        const widthPercentage = this.currentStep * stepPercentage;
        document.documentElement.style.setProperty('--progress-width', `${widthPercentage}%`);
    }

    onClickSchedulerFormBody(event: MouseEvent): void {
        const target = event.target as HTMLInputElement;
        if (!target.closest('.dropdown-menu')) {
            event.stopPropagation();
            this.clearDropdowns();
        }
    }

    onClickTestAddCombinationRule(event: MouseEvent, rule: CombinationRuleItem): void {
        event.stopPropagation();
        this.clearDropdowns();
        rule.testStatusDropdownShow = !rule.testStatusDropdownShow;
    }

    onClickMessageAddCombinationRule(event: MouseEvent, rule: CombinationRuleItem): void {
        event.stopPropagation();
        this.clearDropdowns();
        rule.messageStatusDropdownShow = !rule.messageStatusDropdownShow;
    }

    onClickConsecutive(event: MouseEvent, rule: CombinationRuleItem): void {
        event.stopPropagation();
        this.clearDropdowns();
        rule.isConsecutiveDropdownShow = !rule.isConsecutiveDropdownShow;
    }

    onClickExpectedRepetitions(event: MouseEvent, rule: CombinationRuleItem): void {
        event.stopPropagation();
        this.clearDropdowns();
        rule.repetitionsTargetDropdownShow = !rule.repetitionsTargetDropdownShow;
    }

    onClickAddStatusButton(event: MouseEvent): void {
        event.stopPropagation();
        this.clearDropdowns();
        this.addEmptyCombinationRule();
    }

    addEmptyCombinationRule(): void {
        const emptyRule: CombinationRuleItem = {
            id: this.combinationRuleIndex,
            messageState: null,
            messageStateWait: null,
            testStatus: null,
            testStatusWait: null,
            isConsecutive: false,
            repetitionsTarget: 1
        };
        this.addCombinationRule(emptyRule);
    }

    clearDropdowns(clearWithStatus: boolean = true): void {
        this.reportRules.combinationRules.forEach(cr => {
            cr.messageStatusDropdownShow = false;
            cr.testStatusDropdownShow = false;
            cr.isConsecutiveDropdownShow = false;
            cr.repetitionsTargetDropdownShow = false;
        });
        this.reportRules.finalMessageStates.forEach(fm => {
            fm.isSelectedInCombination = false;
        });
        this.reportRules.finalTestStatuses.forEach(ft => {
            ft.isSelectedInCombination = false;
        });
    }

    addTestCombinationRule(rule: CombinationRuleItem, status: StatusItem): void {
        status.isSelectedInCombination = !status.isSelectedInCombination;
        this.reportRules.finalTestStatuses.forEach(finalStatus => {
            if (finalStatus.id !== status.id) {
                finalStatus.isSelectedInCombination = false;
            }
        });
        rule.testStatus = status.isSelectedInCombination ? status.id : null;
    }

    addMessageCombinationRule(rule: CombinationRuleItem, status: StatusItem): void {
        status.isSelectedInCombination = !status.isSelectedInCombination;
        this.reportRules.finalMessageStates.forEach(ft => {
            if (ft.id !== status.id) {
                ft.isSelectedInCombination = false;
            }
        });
        rule.messageState = status.isSelectedInCombination ? status.id : null;
    }

    addCurrentUserEmail(): void {
        this.userService.getAuthUser().then(_ => {
            if (_.email) {
                this.model.reportEmails.push(_.email);
                this.updateEmails();
            }
        });
    }

    saveExpectedRepetitions(rule: CombinationRuleItem): void {
        const newValue = this.repetitionsTarget;
        if (rule.repetitionsTarget !== newValue) {
            const newRule: CombinationRuleItem = { ...rule, repetitionsTarget: newValue };
            if (this.doesRuleAlreadyExist(newRule)) {
                this.notificationService.error('You are already using this rule. Hence it has been ignored.', 'Duplicate Rule');
            } else {
                rule.repetitionsTarget = newValue;
            }
        }
        rule.repetitionsTargetDropdownShow = false;
    }

    onConsecutiveRepClick(rule: CombinationRuleItem, clickedValue: boolean) {
        if (rule.isConsecutive !== clickedValue) {
            const newRule: CombinationRuleItem = { ...rule, isConsecutive: clickedValue };
            if (this.doesRuleAlreadyExist(newRule)) {
                this.clearDropdowns();
                this.notificationService.error('You are already using this rule. Hence it has been ignored.', 'Duplicate Rule');
            } else {
                rule.isConsecutive = clickedValue;
            }
        }
    }

    onExpectedRepetitionsChange(selectedValue: number) {
        this.repetitionsTarget = selectedValue;
    }

    getIconForStatus(statusID: string): string {
        return CustomUtils.getIconForStatus(statusID);
    }

    switchToStep(stepNumber: number): void {
        // Allow to switch to a future steps only if the current step is valid ...
        if ((stepNumber === 2 && this.validTab(1)) ||
            (stepNumber === 3 && this.validTab(2))) {
            this.currentStep = stepNumber;
            if (this.currentStep === 3 && this.reportRules.combinationRules.length === 0) {
                this.addEmptyCombinationRule();
            }
        }
    }

    prevStep() {
        this.currentStep--;
    }

    nextStep() {
        this.currentStep++;
        if (this.currentStep === 3 && this.reportRules.combinationRules.length === 0) {
            this.addEmptyCombinationRule();
        }
    }

    getConfirmButtonText(): string {
        if (+this.scheduleHistory.countTests > 1000) {
            return `Confirm scheduling ${this.scheduleHistory.countTests} tests`;
        }
        return 'Save';
    }

    shouldAlertBeShown(): boolean {
        const isAnyReportChecked = this.model.repetitionReportPerAlertEnabled || this.model.allAlertXlsReportEnabled || this.model.repetitionAlertXlsReportEnabled;
        if (isAnyReportChecked) {
            if (this.reportRules.combinationRules.length === 1) {
                const { messageState, testStatus } = this.reportRules.combinationRules[0];
                return !messageState && !testStatus;
            }
            return this.reportRules.combinationRules.length === 0;
        }
        return false;
    }
}
