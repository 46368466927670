import { Component, Output, EventEmitter, ElementRef, Input, OnChanges, OnInit } from '@angular/core';
import { NotificationService } from '../../shared/services/notification.service';
import { ValidationService } from '../../shared/services/validation.service';
import { AppscriptsUserService } from '../../shared/services/appscripts-user.service';
import { UsersService } from "../../shared/services/users.service";

@Component({
    selector: 'app-appscripts-balance-form',
    templateUrl: './appscripts-balance-form.component.html',
})

export class AppscriptsBalanceFormComponent implements OnChanges, OnInit {

    @Input() userId;
    @Output() onAfterSave = new EventEmitter();

    loading = false;

    model = {
        REFERRAL: { value: 0, newValue: 0, label: 'Referral' },
        MT_SMS: { value: 0, newValue: 0, label: 'In. Sms' },
        MO_SMS: { value: 0, newValue: 0, label: 'Out. Sms' },
        CALL: { value: 0, newValue: 0, label: 'Call' },
        BONUS: { value: 0, newValue: 0, label: 'Bonus' },
    }

    private note = 'Added by admin'

    constructor(
        protected elementRef: ElementRef,
        protected notificationService: NotificationService,
        protected service: AppscriptsUserService,
        protected validationService: ValidationService,
        protected userService: UsersService
    ) {
    }

    ngOnInit() {
        this.userService.i().subscribe(user => {
            this.note = `Added by ${user.email}`
        });
    }

    getBalanceSum(type = null) {
        let types = type === null ? ['REFERRAL', 'MT_SMS', 'MO_SMS', 'CALL', 'BONUS'] : [type];
        let sum = 0;
        types.forEach(t => {
            sum += this.model[t].value;
            let newValue = parseFloat(this.model[t].newValue)
            sum += (newValue ? newValue : 0);
        });
        return sum === 0 ? 0 : sum;
    }

    onSubmit() {
        let models = [];
        for (let i in this.model) {
            let val = parseFloat(this.model[i].newValue);
            if (val !== 0) {
                models.push({ value: Math.trunc(val * 100), type: i, note: this.note })
            }
        }
        this.loading = true;
        this.service.addBalance(this.userId, models).subscribe({
            next: balances => {
                this.loading = false;
                this.reset();
                this.setData(balances);
                this.notificationService.success('Balance updated', 'Balance');
                this.onAfterSave.emit();
            },
            error: error => {
                this.loading = false;
                this.validationService.handleRequestError(error, 'Balance');
            }
        });
    }

    update() {
        this.reset();
        this.loading = true;
        this.service.getBalance(this.userId).subscribe({
            next: balances => {
                this.setData(balances)
                this.loading = false;
            },
            error: e => {
                this.notificationService.error('Error request', 'Balance');
                this.loading = false;
            }
        });
    }

    private reset() {
        for (let i in this.model) {
            this.model[i].value = 0;
            this.model[i].newValue = 0;
        }
    }

    private setData(balances) {
        for (let type in balances) {
            if (!this.model[type]) { continue; }
            this.model[type].value = balances[type] / 100
        }
    }

    ngOnChanges(changes) {
        if (changes.userId && changes.userId.currentValue) {
            this.update()
        }
    }
}