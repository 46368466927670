import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, EventEmitter, OnInit, Output, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Filter } from '../../shared/filter/filter';
import { InvoiceSearchEvent, InvoiceService, InvoiceStatus } from '../../shared/services/invoice.service';
import { LocalStorageService } from '../../shared/services/localStorage.service';
import { UsersService } from '../../shared/services/users.service';
import { ValidationService, Validators as Vld } from '../../shared/services/validation.service';
import { InputSearchDatePeriod } from '../../shared/components/input/input.search.datePeriod';
import { debounceTime, distinctUntilChanged, Subscription } from 'rxjs';
import { UsersSelectComponent } from '../../users/users-select/users-select.component';

@Component({
    selector: 'app-invoices-search',
    templateUrl: './invoices-search.component.html',
    styleUrls: ['./invoices-search.component.scss']
})
export class InvoicesSearchComponent implements OnInit {

    @Output() onSubmit = new EventEmitter<InvoiceSearchEvent>();
    @ViewChild(InputSearchDatePeriod, { static: false }) datePeriod: InputSearchDatePeriod;
    @ViewChildren(UsersSelectComponent) userSelectComponents: QueryList<UsersSelectComponent>;

    usersCollection = [];
    createdByUsersCollection = [];  
    isBeingDragged: boolean = false;
    filters: Filter[] = [
        { filterKey: 'paymentType', title: 'Type', show: true },
        { filterKey: 'createdAt', title: 'Created on', show: true },
        { filterKey: 'statuses', title: 'Status', show: true },
        { filterKey: 'minimumAmount', title: 'Minimum amount', show: true },
        { filterKey: 'packageNames', title: 'Package', show: false },
        { filterKey: 'companyNames', title: 'Company', show: false },
        { filterKey: 'userIds', title: 'Users', show: false },
        { filterKey: 'createdByUserIds', title: 'Created by', show: false },
    ];
    form: FormGroup;
    invoiceStatuses: InvoiceStatus[] = [];
    searchFormControl: FormControl = new FormControl();
    searchSubscription: Subscription;

    constructor(
        private formBuilder: FormBuilder,
        private usersService: UsersService,
        private localStorage: LocalStorageService,
        public validationService: ValidationService
    ) {
        this.invoiceStatuses = InvoiceService.INVOICE_STATUSES.map(_ => {
            return { value: _, label: this.getLabelForSchedulerStatus(_), icon: this.getIconForStatus(_), isChecked: false };
        });
        this.searchSubscription = this.searchFormControl.valueChanges.pipe(
            debounceTime(500),
            distinctUntilChanged()
        ).subscribe(_ => {
            this.onSubmitClick();
        });
    }

    ngOnInit(): void {
        this.form = this.formBuilder.group({
            paymentTypes: [null],
            createdAtFrom: [null],
            createdAtTo: [null],
            statuses: [null],
            minimumAmount: [null, Vld.compose([Vld.digits(), Vld.min(0)])],
            packageNames: [null],
            companyNames: [null]
        });

        const existingFilters = this.localStorage.get(`invoices_${this.usersService.authUser.id}_filter_state`) || [];
        if (existingFilters.length === this.filters.length) {
            this.filters = existingFilters;
        } else {
            existingFilters.forEach(ef => {
                this.filters.filter(_ => _.filterKey === ef.filterKey).forEach(f => {
                    f.show = ef.show;
                });
            });
        }
    }

    get paymentTypes(): string[] {
        return this.form.get('paymentTypes').value || [];
    }

    drop(event: CdkDragDrop<string[]>) {
        moveItemInArray(this.filters, event.previousIndex, event.currentIndex);
        this.localStorage.set(`invoices_${this.usersService.authUser.id}_filter_state`, this.filters);
    }

    getPeekForSelectedValues(filter): number | string | null {
        switch (filter.filterKey) {
            case 'userIds':
                return this.usersCollection.length;
            case 'createdByUserIds':
                return this.createdByUsersCollection.length;
        }
        return null;
    }

    isFilterActive(filter: Filter): boolean {
        switch (filter.filterKey) {
            case 'paymentType':
                return this.paymentTypes.length > 0;
            case 'createdAt':
                return this.form.get('createdAtFrom').value || this.form.get('createdAtTo').value;
            case 'statuses':
                return this.invoiceStatuses.filter(_ => _.isChecked).length > 0;
            case 'minimumAmount':
                return this.form.get('minimumAmount').value;
            case 'packageNames':
                return this.form.get('packageNames').value?.length > 0;
            case 'companyNames':
                return this.form.get('companyNames').value?.length > 0;
            case 'userIds':
                return this.usersCollection.length > 0;
            case 'createdByUserIds':
                return this.createdByUsersCollection.length > 0;
            default:
                return false;
        }
    }

    onClear(filterKey: string) {
        switch (filterKey) {
            case 'minimumAmount':
                this.form.get('minimumAmount').reset();
                break;
            case 'createdAt':
                this.datePeriod?.reset();
                this.form.get('createdAtFrom').setValue(null);
                this.form.get('createdAtTo').setValue(null);
                this.onChangeCreatedAtDate({ startIso: null, endIso: null });
                break;
            case 'packageNames':
                this.form.get('packageNames').reset();
                break;
            case 'companyNames':
                this.form.get('companyNames').reset();
                break;
            case 'userIds':
                this.usersCollection = [];
                break;
            case 'createdByUserIds':
                this.createdByUsersCollection = [];
                break;
            default:
                break;
        }
        this.onSubmitClick();
    }

    onSelectUser(user) {
        this.usersCollection.push(user);
        this.onSubmitClick();
    }

    onUnSelectUser(user, event: Event = null) {
        if (event) {
            event.stopPropagation();
        }
        this.usersCollection = this.usersCollection.filter(u => user.id !== u.id);
        this.onSubmitClick();
    }

    onSelectCreatedByUser(user) {
        this.createdByUsersCollection.push(user);
        this.onSubmitClick();
    }

    onUnSelectCreatedByUser(user, event: Event = null) {
        if (event) {
            event.stopPropagation();
        }
        this.createdByUsersCollection = this.createdByUsersCollection.filter(u => user.id !== u.id);
        this.onSubmitClick();
    }

    onSubmitClick(filterKey: string = null) {
        let searchData: InvoiceSearchEvent = this.form.getRawValue();
        searchData.statuses = this.invoiceStatuses.filter(_ => _.isChecked).map(_ => _.value);
        searchData.companyNames = this.form.get('companyNames').value?.split(',').map(_ => _.trim());
        searchData.packageNames = this.form.get('packageNames').value?.split(',').map(_ => _.trim());
        searchData.userIds = this.usersCollection.map(_ => _.id);
        searchData.createdByUserIds = this.createdByUsersCollection.map(_ => _.id);
        searchData.searchString = this.searchFormControl.value;
        this.onSubmit.emit(searchData);
    }

    resetAll(): void {
        this.form.reset();
        this.usersCollection = [];
        this.createdByUsersCollection = [];
        this.userSelectComponents.forEach(_ => _.resetAll());
        this.invoiceStatuses.forEach(_ => _.isChecked = false);
        this.searchFormControl.reset();
    }

    shouldAddFilterBeShown(): boolean {
        const hiddenFilters = this.filters.filter(_ => _.show === false);
        return hiddenFilters.length > 0;
    }

    deleteItem(event: CdkDragDrop<string[]>) {
        const indexToHide = event.previousIndex;
        this.filters[indexToHide].show = false;
        this.onClear(this.filters[indexToHide].filterKey);
        this.localStorage.set(`invoices_${this.usersService.authUser.id}_filter_state`, this.filters);
    }

    onAdditionalFilterClick(filter): void {
        filter.show = !filter.show;
        this.localStorage.set(`invoices_${this.usersService.authUser.id}_filter_state`, this.filters);
    }

    onClearAllFilter(): void {
        this.localStorage.set(`invoices_${this.usersService.authUser.id}_filter_state`, this.filters);
        this.resetAll();
        this.onSubmitClick();
    }

    onChangePaymentSelection(paymentType: string): void {
        const paymentTypeFormControl: FormControl = this.form.get('paymentTypes') as FormControl;
        const selectedPaymentTypeValue = paymentTypeFormControl.value ? paymentTypeFormControl.value : [];
        if (selectedPaymentTypeValue.includes(paymentType)) {
            paymentTypeFormControl.setValue(selectedPaymentTypeValue.filter(_ => _ !== paymentType));
        } else {
            paymentTypeFormControl.setValue([...selectedPaymentTypeValue, paymentType]);
        }
        this.onSubmitClick();
    }

    onChangeCreatedAtDate(dates): void {
        const { startIso, endIso } = dates;
        this.form.get('createdAtFrom').setValue(startIso);
        this.form.get('createdAtTo').setValue(endIso);
        this.onSubmitClick();
    }

    onChangeStatus(status): void {
        status.isChecked = !status.isChecked;
        this.form.get('statuses').setValue(this.invoiceStatuses.filter(_ => _.isChecked));
        this.onSubmitClick();
    }

    getLabelForSchedulerStatus(status: string): string {
        return status[0] + status.slice(1).toLowerCase();
    }

    getIconForStatus(status: string): string {
        switch (status) {
            case 'WAITING':
                return 'icon-wait fs-4';
            case 'PROCESSING':
                return 'icon-wait fs-4 text-warning-w2';
            case 'CONFIRMED':
                return 'icon-positive text-sucess-s2 fs-4';
            case 'PARTIALLY_PAID':
                return 'icon-check text-warning-w2 fs-5';
            case 'VOID':
                return 'icon-close text-error-r3 fs-5';
            default:
                return '';
        }
    }

}
