import { AfterViewInit, Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, catchError, forkJoin, map } from 'rxjs';
import { BreadcrumbService } from '../../../shared/components/breadcrumbs/breadcrumbs';
import { TestResult } from '../../../shared/models/test-result.model';
import { BatchTasks } from '../../../shared/services/batch-tasks';
import { CustomUtils } from '../../../shared/services/custom-utils';
import { DatadogService } from '../../../shared/services/datadog.service';
import { LiveNumberTestingService } from '../../../shared/services/live-number-testing.service';
import { DialogRef, ModalService } from '../../../shared/services/modal.service';
import { NotificationService } from '../../../shared/services/notification.service';
import { PricingService } from '../../../shared/services/pricing.service';
import { UsersService } from '../../../shared/services/users.service';
import { LntFormComponent } from '../lnt-form/lnt-form.component';
import { LntResultsTableComponent } from '../lnt-results-table/lnt-results-table.component';
import { LntSearchFormComponent } from '../lnt-search-form/lnt-search-form.component';

@Component({
    selector: 'app-lnt-index',
    templateUrl: 'lnt-index.component.html',
    styleUrls: ['./lnt-index.component.scss']

})

export class LntIndexComponent implements OnInit, AfterViewInit {

    @ViewChild(LntResultsTableComponent, { static: false }) resultsTable: LntResultsTableComponent;
    @ViewChild(LntFormComponent, { static: false }) form: LntFormComponent;
    @ViewChild(LntSearchFormComponent, { static: false }) filter: LntSearchFormComponent;

    @ViewChild('batchRepeatModal', { read: TemplateRef, static: false }) batchRepeatModalTpl: any;
    batchRepeatModalDialogRef: DialogRef;

    @ViewChild('reimburseModalTpl', { read: TemplateRef, static: false }) reimburseModalTpl: any;

    collapseSearch: boolean = false;
    pauseUpdateForm: boolean = false;
    pauseUpdateResults: boolean = false;
    selectedRows: TestResult[] = [];
    isBatchRepeatLoading: boolean = false;
    batchCompleted: number = 0;
    testsPrice: number = 0;

    testingDisabled = null;

    loading = false;
    isAdmin: boolean = false;

    constructor(
        public breadcrumbs: BreadcrumbService,
        public router: Router,
        public route: ActivatedRoute,
        public notificationService: NotificationService,
        public userService: UsersService,
        titleService: Title,
        private modal: ModalService,
        private pricingService: PricingService,
        private liveNumberService: LiveNumberTestingService,
        private dgService: DatadogService,
    ) {
        titleService.setTitle('Test case create');
    }

    afterSave(res) {
        if (res.runForScheduler) {
            this.router.navigate(['/scheduler/create'], { queryParams: { test_case_id: res.id } }).then();
        } else {
            this.pauseUpdateResults = false;
            this.resultsTable.currentPageNumber = 1;
            this.resultsTable.update();
        }
    }

    onSubmitFilter(data) {
        if (this.resultsTable.loading) {
            return;
        }
        this.pauseUpdateResults = true;
        this.resultsTable.searchParams = data;
        this.resultsTable.currentPageNumber = 1;
        this.resultsTable.update();
    }

    ngOnInit() {
        this.breadcrumbs
            .root('Dashboard', ['dashboard'])
            .add('TestCases', ['test/list'])
            .add('Create', ['test/create'], true);
    }

    ngAfterViewInit() {
        this.userService.getAuthUser().then(u => {
            this.isAdmin = u.role === 'admin';
        });
        this.route.queryParams.subscribe(queryParams => {
            if (queryParams.ids) {
                const interval = setInterval(() => {
                    if (!this.resultsTable) {
                        return;
                    }
                    clearInterval(interval);
                    const ids = queryParams.ids.split(',').map(_ => parseInt(_.trim()))
                    this.filter.filters.forEach(_ => {
                        if (_.filterKey === 'searchById') _.show = true;
                    });
                    this.filter.form.patchValue({ searchById: queryParams.ids });
                    this.filter.model.ids = ids.join(' ');
                    this.resultsTable.searchParams = { ids };
                    this.resultsTable.currentPageNumber = 1;
                    this.resultsTable.update();
                }, 1000);
            }
        });
    }

    onRepeat(testCaseResult: TestResult) {
        this.form.loading = true;
        window.scrollTo({ top: 0, behavior: 'smooth' });
        this.form.reset();
        this.form.loadTestCase(testCaseResult.testGroupId);
    }

    onCollapseSearch(data: any = null) {
        this.collapseSearch = !this.collapseSearch;
        this.pauseUpdateForm = this.collapseSearch;
        this.resultsTable.searchParams = data;
        this.resultsTable.currentPageNumber = 1;
        this.resultsTable.update();
    }

    startTasks(tasks: Observable<any>[]) {
        this.batchCompleted = 0;
        this.resultsTable.loading = true;
        (new BatchTasks(tasks).run(4));
    }

    completeTask() {
        this.batchCompleted++;
        if (this.selectedRows.length === this.batchCompleted) {
            this.resultsTable.loading = false;
            this.selectedRows = [];
            this.isBatchRepeatLoading = false;
            this.batchRepeatModalDialogRef.close();
            this.resultsTable.resetBatch();
            this.resultsTable.update();
        }
    }

    batchClear(): void {
        this.selectedRows = [];
        this.resultsTable.resetBatch();
    }

    onBatchRepeat(): void {
        this.isBatchRepeatLoading = true;
        this.batchRepeatModalDialogRef = this.modal.alert().dialogClass('modal-dialog extra-large-modal').component(this.batchRepeatModalTpl).open();
        this.pricingService.getTestPrices(this.selectedRows.length).subscribe({
            next: (result: number) => {
                this.testsPrice = result;
                this.isBatchRepeatLoading = false;
            },
        });
    }

    onBatchReimburse(): void {
        const dialogRef = this.modal.alert().dialogClass('modal-dialog small-modal').component(this.reimburseModalTpl).open();
        dialogRef.result.then((_) => {
            if (_) {
                const testIds = this.selectedRows.map(_ => _.id);
                this.liveNumberService.reimburse(testIds).subscribe({
                    next: () => {
                        this.notificationService.success('Test cases reimbursed', 'Live Number Testing');
                        this.resultsTable.update();
                        this.batchClear();
                    },
                    error: () => {
                        this.notificationService.error('Test cases reimbursement failed', 'Live Number Testing');
                    },
                });
            }
        });

    }

    onRepeatClick(): void {
        this.isBatchRepeatLoading = true;
        const testGroupIds = this.selectedRows.map(_ => _.testGroupId);
        const testGroupSettingsMap = {};
        testGroupIds.forEach(groupId => testGroupSettingsMap[groupId] = this.liveNumberService.oneTestGroup(groupId));
        forkJoin(testGroupSettingsMap).subscribe(testGroupSettings => {
            const tasks = this.selectedRows.map(row => {
                const test: TestResult = row;
                const testGroup = CustomUtils.getMappedTestToTestGroupStructure(test, testGroupSettings[test.testGroupId]);
                console.debug(`Repeat Test start by ${this.userService.authUser.id}: `, testGroup);
                return this.liveNumberService.run(testGroup).pipe(map(() => {
                    console.debug(`Repeat Test successfull  by ${this.userService.authUser.id}: `, testGroup);
                    this.notificationService.success(`Test repeated for ID: ${test.id}`, 'Live Number Testing');
                    this.completeTask();
                }), catchError((err, caught) => {
                    this.notificationService.error(`Test repeat failed for ID: ${test.id}`, 'Live Number Testing');
                    this.completeTask();
                    return caught;
                }));
            });
            this.startTasks(tasks);
        });
    }

}
