import { Location } from '@angular/common';
import { Component, TemplateRef, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { Subscription, debounceTime, distinctUntilChanged } from 'rxjs';
import { DialogRef, ModalService } from '../../shared/services/modal.service';
import { NotificationService } from '../../shared/services/notification.service';
import { SchedulerSearchEvent, SchedulerService, SchedulerTaskActionData } from "../../shared/services/scheduler.service";
import { UsersService } from '../../shared/services/users.service';
import { SchedulerTableComponent } from '../scheduler-table/scheduler-table.component';
import { SchedulerSearchComponent } from '../scheduler-search/scheduler-search.component';

@Component({
    selector: 'app-scheduler-index',
    templateUrl: './scheduler-index.component.html',
    styleUrls: ['./scheduler-index.component.scss'],
})
export class SchedulerIndexComponent {
    @ViewChild(SchedulerTableComponent, { static: false }) table: SchedulerTableComponent;
    @ViewChild(SchedulerSearchComponent, { static: false }) schedulerSearchComponent: SchedulerSearchComponent;
    @ViewChild('schedulerCreateModalTpl', { read: TemplateRef, static: false }) schedulerCreateModalTpl: any;
    @ViewChild('schedulerStopTaskModalTpl', { read: TemplateRef, static: false }) schedulerStopTaskModalTpl: any;
    schedulerCreateModal: DialogRef;
    schedulerStopTaskModal: DialogRef;
    hiddenMode: boolean;
    showMyTasks: boolean;
    isTableInitialized: boolean = false;
    isAdmin: boolean;
    serviceDisabled = null;
    searchControl = new FormControl();
    private searchSubscription: Subscription;

    constructor(
        public router: Router,
        private route: ActivatedRoute,
        public notificationService: NotificationService,
        public service: SchedulerService,
        public usersService: UsersService,
        titleService: Title,
        private modal: ModalService,
        private location: Location
    ) {
        titleService.setTitle('Scheduled tasks');
        this.router = router;
        this.notificationService = notificationService;
        this.hiddenMode = this.service.getHiddenMode();
        this.showMyTasks = this.service.getShowMyTasksFlag();
        this.usersService.can('admin').then(admin => {
            this.isAdmin = admin;
        });
    }

    ngOnInit() {
        this.searchSubscription = this.searchControl.valueChanges.pipe(
            debounceTime(500),
            distinctUntilChanged()
        ).subscribe(searchValue => {
            this.onChangeInputSearch(searchValue);
        });
    }

    ngAfterViewChecked() {
        if (!this.isTableInitialized) {
            this.route.queryParams.subscribe(queryParams => {
                if (queryParams.ids && this.table) {
                    this.isTableInitialized = true;
                    const ids = queryParams.ids.split(',');
                    this.schedulerSearchComponent.filters.forEach(_ => {
                        if (_.filterKey === 'ids') _.show = true;
                    });
                    this.schedulerSearchComponent.form.patchValue({ ids: queryParams.ids });

                    this.table.currentPageNumber = 1;
                    this.table.requestParams.searchData = { ...this.service.getEmptySearchObject(), ids, hidden: this.hiddenMode, ownTasksOnly: this.showMyTasks };
                }
            });
        }
    }

    onScheduleAfterSave(data: boolean): void {
        this.schedulerCreateModal.close();
        this.clearUrlQuery();
        this.table.update();
    }

    clearUrlQuery(): void {
        // Clear the URL with any ids present ...
        const currentPath = this.location.path();
        const currentHistoryState = this.location.getState();
        const newPath = currentPath.split('?')[0];
        this.location.replaceState(newPath, '', currentHistoryState);
    }

    schedulerAction(event: SchedulerTaskActionData) {
        if (event.name === 'clone') {
            if (event.row.state === 'SCHEDULED' || event.row.state === 'RUNNING') {
                this.schedulerStopTaskModal = this.modal.alert().dialogClass('modal-dialog small-modal').component(this.schedulerStopTaskModalTpl).open();
                this.schedulerStopTaskModal.result.then((res: boolean) => {
                    if (res === null) {
                        this.schedulerStopTaskModal.close();
                        return;
                    }
                    if (res) {
                        this.stopTask(event);
                    } else {
                        this.notificationService.info('Current task has not been stopped.');
                        this.openModal(event);
                    }
                });
            } else {
                this.openModal(event);
            }
        }
    }

    stopTask(event: SchedulerTaskActionData) {
        this.service.stop(event.row.id).subscribe({
            next: () => {
                this.notificationService.success('Current task has been stopped');
                this.openModal(event);
            },
            error: (error) => {
                console.error(error);
                this.notificationService.error('Cannot stop current task. Please try again later.', 'Scheduler');
            }
        });
    }

    openModal(event: SchedulerTaskActionData) {
        const currentUrl = this.router.url.split('?')[0];
        const navigationExtras: NavigationExtras = {
            queryParams: { 'id': event.row.id },
            queryParamsHandling: 'merge',
            preserveFragment: true,
        };
        this.router.navigate([currentUrl], navigationExtras);
        this.schedulerCreateModal = this.modal.alert().dialogClass('modal-dialog full-modal').component(this.schedulerCreateModalTpl).open();
        this.schedulerCreateModal.result.then((res: boolean) => {
            this.router.navigate([currentUrl]);
        });
    }

    onChangeHiddenMode() {
        this.service.saveHiddenMode(this.hiddenMode);
        this.table.requestParams.searchData = { ...this.table.requestParams.searchData, hidden: this.hiddenMode };
        this.table.update();
    }

    onShowMyTasksFlag() {
        this.table.requestParams.searchData = { ...this.table.requestParams.searchData, ownTasksOnly: this.showMyTasks };
        this.table.update();
    }

    onChangeInputSearch(phrase) {
        this.table.requestParams.search = phrase;
        this.table.requestParams.page = 1;
        this.table.update();
    }

    onClickCreateSchedule(): void {
        this.schedulerCreateModal = this.modal.alert().dialogClass('modal-dialog full-modal').component(this.schedulerCreateModalTpl).open();
        this.schedulerCreateModal.result.then((res: boolean) => {
            this.clearUrlQuery();
        });
    }

    onSubmitSearchForm(event: SchedulerSearchEvent) {
        if (this.table.loading) {
            return;
        }
        this.table.currentPageNumber = 1;
        this.table.requestParams.searchData = { ...event, hidden: this.hiddenMode, ownTasksOnly: this.showMyTasks };
        this.table.update();
    }

    ngOnDestroy() {
        this.searchSubscription.unsubscribe();
    }
}
