import { Clipboard } from '@angular/cdk/clipboard';
import {
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    TemplateRef,
    ViewChild
} from '@angular/core';
import { Router } from '@angular/router';
import { CellClickedEvent, ColDef, ColumnMovedEvent, ColumnResizedEvent, ColumnVisibleEvent, GetContextMenuItemsParams, GridReadyEvent, ICellRendererParams, MenuItemDef, RowSelectedEvent } from 'ag-grid-community';
import { diffChars } from 'diff/lib/index';
import * as Highcharts from 'highcharts';
import { Observable, Subscription, debounceTime, forkJoin } from "rxjs";
import { PhoneReportsWidgetComponent } from "../../../phone-reports/phone-reports-widget/phone-reports-widget.component";
import { AGTableBase } from '../../../shared/components/table/ag-table-base';
import { SmscInfoDto, TestDetails, TestResult, TestResultAction, TestResultsCollection } from "../../../shared/models/test-result.model";
import { AuthUser, Role } from '../../../shared/models/user.model';
import { BrowserUtils } from "../../../shared/services/browser-utils";
import { CustomUtils } from "../../../shared/services/custom-utils";
import { ExportService } from '../../../shared/services/export.service';
import { AllRequestParams, LiveNumberTestingService, SearchParams } from '../../../shared/services/live-number-testing.service';
import { LiveRealtimeService } from '../../../shared/services/live-realtime-service';
import { LocalStorageService } from '../../../shared/services/localStorage.service';
import { DialogRef, ModalService } from "../../../shared/services/modal.service";
import { NotificationService } from '../../../shared/services/notification.service';
import { SessionsService } from '../../../shared/services/sessions.service';
import { UsersService } from '../../../shared/services/users.service';
import { LntSearchFormComponent } from '../lnt-search-form/lnt-search-form.component';
import { LntActionsComponent } from './lnt-actions/lnt-actions.component';

declare var moment: any;

@Component({
    selector: 'app-lnt-results-table',
    templateUrl: 'lnt-results-table.component.html',
    styleUrls: ['lnt-results-table.component.scss'],
})

export class LntResultsTableComponent extends AGTableBase implements OnInit, OnDestroy {

    @ViewChild(PhoneReportsWidgetComponent, { static: false }) phonenumberReportWidget: PhoneReportsWidgetComponent;
    @ViewChild('exportModalTpl', { read: TemplateRef, static: false }) exportModalTpl: any;
    @ViewChild('copyModalTpl', { read: TemplateRef, static: false }) copyModalTpl: any;
    @ViewChild('senderModalTpl', { read: TemplateRef, static: false }) senderModalTpl: any;
    @ViewChild('textModalTpl', { read: TemplateRef, static: false }) textModalTpl: any;
    @ViewChild('smscModalTpl', { read: TemplateRef, static: false }) smscModalTpl: any;
    @ViewChild('connectionModalTpl', { read: TemplateRef, static: false }) connectionModalTpl: any;
    @ViewChild('latencyModalTpl', { read: TemplateRef, static: false }) latencyModalTpl: any;
    @ViewChild('commentModalTpl', { read: TemplateRef, static: false }) commentModalTpl: any;
    @ViewChild('reimburseModalTpl', { read: TemplateRef, static: false }) reimburseModalTpl: any;

    @Input() taskId: number;
    @Input() batchRepeatRows: TestResult[] = [];
    @Input() compact: boolean = false;
    @Input() pauseUpdate: boolean = false;
    @Output() changeBatch = new EventEmitter<TestResult[]>();
    @Output() onRepeat = new EventEmitter<TestResult>();
    @Output() actions = new EventEmitter<unknown>();

    // ng grid
    columnDefs: ColDef[] = [
        {
            headerName: '', field: 'batch', checkboxSelection: true,
            maxWidth: 40, headerCheckboxSelection: true, pinned: 'left',
            lockPinned: true, lockPosition: 'left', lockVisible: true,
            suppressColumnsToolPanel: true, suppressMenu: true, suppressMovable: true
        },
        {
            headerName: 'ID', field: 'id', hide: true, flex: 0.5,
            cellRenderer: (params: ICellRendererParams) => {
                return `<div class="one-line">${params.data.id}</div>`;
            },
            onCellClicked: (event: CellClickedEvent) => {
                const id = event.data.id;
                this.clipBoard.copy(id);
            },
        },
        {
            headerName: 'Date', field: 'date', minWidth: 90, maxWidth: 90,
            flex: 0.6, headerTooltip: 'Date and time when the test was triggered, in the browser’s time zone',
            cellRenderer: (params: ICellRendererParams) => {
                const _ = params.data;
                return this.formatDate(_);
            },
        },
        {
            headerName: 'UTC Date', field: 'utcTimestamp', minWidth: 90, maxWidth: 90,
            flex: 0.6, headerTooltip: 'UTC date for test created date.', hide: true,
            cellRenderer: (params: ICellRendererParams) => {
                const _ = params.data;
                return CustomUtils.formatDateIntoUTC(_.createdAt)
            },
        },
        {
            headerName: 'Country(MCC)/Network(MNC)', field: 'destinationDto',
            flex: 1.5, headerTooltip: 'The destination to which the test was sent',
            cellRenderer: (params: ICellRendererParams) => {
                return `<div class="two-lines">${params.value ? this.formatCountry(params.value) : ''}</div>`;
            }
        },
        {
            headerName: 'DLR/Rec. Status', field: 'dlrRecStatus',
            minWidth: 90, maxWidth: 90,
            flex: 0.6, headerTooltip: 'DLR status - Delivery Report received from the supplier via SMPP, Rec.status - Receipt Status received from our test number.',
            cellClass: 'align-items-center justify-content-center',
            cellRenderer: (params: ICellRendererParams) => {
                const row = params.data;
                return this.formatDlrRecStatus(row);
            }
        },
        {
            headerName: 'Sender ID', field: 'senderDelivered',
            minWidth: 130,
            flex: 1, tooltipField: 'senderDelivered', headerTooltip: 'Sender ID of the delivered SMS (submitted Sender for undelivered SMS)',
            cellRenderer: (params: ICellRendererParams) => {
                const row = params.data;
                return this.formatSenderDelivered(row);
            },
            onCellClicked: (event: CellClickedEvent) => this.onCellClick(event),
        },
        {
            headerName: 'Submitted Sender ID', field: 'senderSent',
            hide: true, flex: 0.7, tooltipField: 'senderSent', headerTooltip: 'Sender ID of the sent SMS',
            cellRenderer: (params: ICellRendererParams) => {
                const row = params.data;
                return this.formatSenderSent(row);
            },
            onCellClicked: (event: CellClickedEvent) => this.onCellClick(event),
        },
        {
            headerName: 'Text', field: 'textDeliveredFull',
            flex: 1.5, headerTooltip: 'Text of the delivered SMS (submitted Text for undelivered SMS)',
            cellRenderer: (params: ICellRendererParams) => {
                const row = params.data;
                let className = '';
                if (row.textDeliveredFull) {
                    className = row.textDeliveredFull !== row.textSentBackend ? 'text-error-r3' : 'text-sucess-s2';
                }
                let text = BrowserUtils.escapeHtml(row.textDeliveredFull ? row.textDeliveredFull : row.textSentBackend);
                return `<div class="one-line clickable"><span class="${className}">${text}</span></div>`;
            },
            onCellClicked: (event: CellClickedEvent) => this.onCellClick(event),
        },
        {
            headerName: 'Phone/SMSC', field: 'phoneSmsc',
            flex: 1, minWidth: 130,
            headerTooltip: 'Test number to which the test SMS was sent',
            cellRenderer: (params: ICellRendererParams) => {
                const row = params.data;
                return this.formatPhoneSmsc(row);
            },
            onCellClicked: (event: CellClickedEvent) => this.onCellClick(event),
        },
        {
            headerName: 'Phone', field: 'phone',
            flex: 1, minWidth: 130, hide: true,
            headerTooltip: 'Test number to which the test SMS was sent',
            cellRenderer: (params: ICellRendererParams) => {
                const row = params.data;
                return this.formatPhone(row);
            },
            onCellClicked: (event: CellClickedEvent) => this.onCellClick(event),

        }, {
            headerName: 'SMSC', field: 'smsc',
            flex: 1, minWidth: 130, hide: true,
            cellRenderer: (params: ICellRendererParams) => {
                const row = params.data;
                return this.formatSmsc(row);
            },
            onCellClicked: (event: CellClickedEvent) => this.onCellClick(event)
        },
        {
            headerName: 'Supplier', field: 'supplierDto',
            headerTooltip: 'Supplier, via which the test was sent',
            cellRenderer: (params: ICellRendererParams) => {
                const row = params.data;
                return `<div class="two-lines clickable">${this.formatConnection(row)}</div>`;
            },
            onCellClicked: (event: CellClickedEvent) => this.onCellClick(event),
        }, {
            headerName: 'DLR/Rec. delay', field: 'delay',
            minWidth: 90, maxWidth: 90,
            flex: 0.6, headerTooltip: 'Delays in getting the SMPP DLR status and Receipt status',
            cellRenderer: (params: ICellRendererParams) => {
                const row = params.data;
                return `<div class="two-lines clickable">${this.formatDelay(row)}</div>`;
            },
            onCellClicked: (event: CellClickedEvent) => this.onCellClick(event),
        },
        {
            headerName: 'User', field: 'user',
            flex: 0.5, headerTooltip: 'User that triggered the test',
            tooltipField: 'user',
            cellRenderer: (params: ICellRendererParams) => {
                const _ = params.data;
                return `<div class="one-line">${_.user ? (_.user.username ? _.user.username : _.user.email) : 'N/A'}</div>`;
            }
        },
        {
            headerName: 'Price', field: 'price',
            flex: 0.4,
            cellRenderer: (params: ICellRendererParams) => {
                const _ = params.data;
                return `<div class="one-line">${_.price > 0 ? `${_.price / 1000}` : '0'}</div>`;
            }
        },
        {
            headerName: 'Comment', field: 'commentText',
            flex: 1, hide: true,
            cellRenderer: (params: ICellRendererParams) => {
                const _ = params.data;
                return `<div class="one-line">${_.commentText ? _.commentText : ''}</div>`;
            }
        },
        {
            headerName: 'Actions', field: 'actions', maxWidth: 100, minWidth: 100,
            pinned: 'right', lockPinned: true, lockPosition: 'right', lockVisible: true,
            suppressColumnsToolPanel: false, suppressMenu: false, suppressAutoSize: true,
            tooltipField: 'actions',
            headerTooltip: 'Right click on a row to see more options.',
            headerClass: 'action-cell',
            cellClass: 'action-cell',
            cellRenderer: LntActionsComponent,
            onCellClicked: (event: CellClickedEvent) => this.onActionClick(event),
        }
    ];
    rowData!: TestResultAction[];
    // ng grid

    exportIsArchive = 0;
    commentModel;
    batchSelectedIds: Set<number> = new Set();
    currentSection: string = '';
    testCaseDetails: TestDetails[] = [];
    intervalTimer: any;
    connectionSpinner = false;
    latencySpinner = false;
    connection: any = null;
    isAdmin: boolean = false;
    exportSize = 500;
    exportSizes = [500, 1000, 3000, 5000, 'All'];
    exportData: any = {};
    exportSpinner = false;
    loading: boolean = false;
    diff = {
        title: '',
        title1: '',
        value1: '',
        title2: '',
        value2: '',
        result: '',
        senderPduHex: ''
    };
    smscData = {
        smsc: {
            phone: '',
            phoneFromPdu: '',
            country: '',
            provider: '',
            mcc: '',
            mnc: ''
        },
        test: {
            phone: '',
            country: '',
        },
        country: '',
        isError: false
    };

    searchParams: SearchParams;

    resultMap = [];
    user: AuthUser;

    commentModal: DialogRef;
    supplier: any;
    session: any;
    statusUpdates$: Subscription;

    Highcharts: typeof Highcharts = Highcharts;
    smppChartOptions: Highcharts.Options;
    smsChartOptions: Highcharts.Options;
    selectedTestIdForCharts: number;
    smppChartMessageTitle?: string;
    smppChartMessageDesc?: string;
    smsChartMessageTitle?: string;

    constructor(
        public router: Router,
        public notificationService: NotificationService,
        public service: LiveNumberTestingService,
        public connectionService: SessionsService,
        public userService: UsersService,
        public localStorage: LocalStorageService,
        public modal: ModalService,
        public exportService: ExportService,
        public clipBoard: Clipboard,
        public liveRealtimeService: LiveRealtimeService,
    ) {
        super();
    }


    ngOnInit() {
        this.userService.getAuthUser().then(user => {
            this.user = user;
            this.isAdmin = user.role === Role.ADMIN;
            this.init();
        }).catch(e => { });
        this.actionSubscription = this.service.action$.subscribe(
            action => this.onAction(action)
        );
        this.columnChange$.pipe(
            debounceTime(1000)
        ).subscribe((event: ColumnMovedEvent | ColumnResizedEvent | ColumnVisibleEvent) => {
            const currentColumnState = this.gridApi.getColumnState();
            this.localStorage.set(`lnt_table_state_${this.user.id}`, currentColumnState);
        });
    }

    init() {
        let hideColumns = [];
        if (!this.isAdmin && !this.user.impersonated) {
            hideColumns.push('Price');
        }
        if (this.user.role === 'subaccount' && !this.user.showAllResults) {
            hideColumns.push('User');
        }
        if (this.compact) {
            hideColumns.push('');
            hideColumns.push('ID');
            hideColumns.push('User');
            hideColumns.push('Price');
        }
        this.columnDefs = this.columnDefs.filter(c => !hideColumns.includes(c.headerName));
        const savedUserSearchDetails = this.localStorage.get(`lnt_user_search_${this.user.id}_search`);
        this.paginationPageSize = this.localStorage.get(`lnt_results_table_component_size_${this.user.id}`) || 20;
        if (savedUserSearchDetails) {
            this.searchParams = savedUserSearchDetails;
        }

        this.intervalTimer = setInterval(() => {
            if (this.loading) {
                return;
            }
            this.update(false);
        }, 40 * 1000);

        this.listenRealTimeUpdates();
    }

    formatCountry(c, icon = true) {
        return this.service.formatDestination(c, icon);
    }

    formatDate(_: TestResultAction): string {
        return `<div class="two-lines"><span title="${_.taskId ? 'Scheduled' : ''}" class="${_.taskId ? 'text-primary' : ''}">${_.createdAtFormat[1]} <br>${_.createdAtFormat[0]}</span></div>`
    }

    formatDateIntoUTC(_: TestResultAction): string {
        const utcDate = moment.utc(_.createdAt).format('DD/MM/YY HH:mm:ss').split(' ');
        return `<div class="two-lines"><span>${utcDate[1]}<br>${utcDate[0]}</span></div>`;
    }

    formatSenderDelivered(row: TestResultAction) {
        let className = '';
        if (row.senderDelivered) {
            className = row.senderDelivered !== row.senderSent ? 'text-error-r3' : 'text-sucess-s2';
        }
        let text = BrowserUtils.escapeHtml(row.senderDelivered ? row.senderDelivered : row.senderSent);
        return `<div class="two-lines clickable"><span class="${className}">${text}</span></div>`;
    }

    formatSenderSent(row: TestResultAction) {
        let text = BrowserUtils.escapeHtml(row.senderSent);
        return `<div class="two-lines"><span>${text}</span></div>`;
    }

    formatDlrRecStatus(row: TestResultAction) {
        let statuses = [];
        let sStatusDef = {
            pending: '<span class="far fa-meh text-accent-a2" title="Pending"></span>',
            scheduled: '<span class="far fa-meh text-accent-a2" title="Scheduled"></span>',
            unknown: '<span class="far fa-meh text-accent-a2" title="Unknown"></span>',
            enroute: '<span class="far fa-meh text-accent-a2" title="Enroute"></span>',
            delivered: '<span class="far fa-smile text-sucess-s2" title="Delivered"></span>',
            expired: '<span class="far fa-frown text-error-r3" title="Expired"></span>',
            deleted: '<span class="far fa-frown text-error-r3" title="Deleted"></span>',
            undeliverable: '<span class="far fa-frown text-error-r3" title="Undeliverable"></span>',
            accepted: '<span class="far fa-meh text-accent-a2"  title="Accepted"></span>',
            rejected: '<span class="far fa-frown text-error-r3" title="Rejected"></span>',
            skipped: '<span class="far fa-frown text-error-r3" title="Skipped"></span>',
            no_dlr_received: '<span class="far fa-frown text-accent-a2" title="No DLR received"></span>',
            partially_delivered: '<span class="far fa-smile text-accent-a2" title="Partially delivered"></span>',
            submit_sm_failed: '<span class="far fa-frown text-error-r3" title="SUBMIT_SM failed"></span>',
            def: '<span class="icon-wait spin" title="Wait"></span>'
        };
        let rStatusDef = {
            wait: '<span class="icon-wait spin" title="Wait"></span>',
            positive: '<span class="icon-positive text-sucess-s2" title="Positive"></span>',
            sender_replaced: '<span class="icon-attention text-accent-a2" title="Sender ID replaced"></span>',
            text_replaced: '<span class="icon-attention text-accent-a2" title="Text replaced"></span>',
            test_number_offline: '<span class="icon-negative text-accent-a2" title="Test number offline"></span>',
            test_number_not_available: '<span class="icon-test-number-not-available text-accent-a2" title="Test number not available"></span>',
            network_offline: '<span class="icon-network-offline text-error-r3" title="Network offline"></span>',
            not_delivered: '<span class="icon-negative text-error-r3" title="Negative"></span>',
            def: '<span class="icon-negative text-error-r3" title="Negative"></span>'
        };

        let status = row.testStatus.toLowerCase();

        if (status === 'internal_error') {
            statuses.push('<span class="icon-bug-red fs-3 text-error-r3" title="Internal error happend during test execution, you won\'t be charged. Try to re-execute the test."></span>')
        } else if (status === 'smpp_session_offline') {
            statuses.push('<span class="icon-smpp-connection-offline text-error-r3" title="SMPP Connection offline"></span>')
        } else {
            let state = row.messageState.toLowerCase();
            let key = typeof sStatusDef[state] !== 'undefined' ? state : 'def';
            statuses.push(sStatusDef[key]);

            key = typeof rStatusDef[status] !== 'undefined' ? status : 'def';
            statuses.push(rStatusDef[key]);
        }
        return '<div class="h-100 d-flex align-items-center justify-content-center fs-2">' + statuses.join('<span class="fs-3 mx-0_2 fw-normal">/</span>') + '</div>';
    }

    formatPhoneSmsc(row: TestResultAction) {
        let result = [];
        if (row.destinationDto.phone) {
            result.push(`<span>${row.destinationDto.phone}</span>`);
        }
        let hlr = row.smscInfoDto;
        if (hlr !== null) {
            let onlyNumber = hlr.phone && (!hlr.countryName || !hlr.countryCode);
            if ((hlr.countryName && hlr.countryCode) || onlyNumber) {
                let className = ((hlr.countryName !== row.destinationDto.countryName) || onlyNumber) ? 'text-error-r3' : 'text-sucess-s2';
                let title = onlyNumber ? hlr.phone : `${hlr.countryName} (${hlr.phone})`;
                let content = onlyNumber ? '?' : hlr.countryCode;
                result.push(`<span class="${className}" title="${title}">${content}</span>`);
            }
        }
        return '<div class="two-lines clickable">' + result.join('<span class="delimiter">/</span>') + '</div>';
    }

    formatPhone(row: TestResultAction): string {
        return `<div class="two-lines clickable"><span>${row.destinationDto.phone || '-'}</span></div>`;
    }

    formatSmsc(row: TestResultAction): string {
        const phone = row.smscInfoDto?.phone || '-';
        return phone === '-' ? '-' : `<div class="two-lines clickable"><span>${phone}</span></div>`;
    }

    changeSize($event, size) {
        this.paginationPageSize = size;
        this.localStorage.set(`lnt_results_table_component_size_${this.user.id}`, size);
        this.update();
    }

    handleBatchRepeat() {
        this.loading = true;
        this.rowData = this.batchRepeatRows;
        this.gridApi.updateGridOptions({ rowData: this.rowData });
        this.totalRowsCount = this.batchRepeatRows.length;
        this.loading = false;
    }

    shouldSearch(): boolean {
        for (const key in this.searchParams) {
            if (!['sort', 'size', 'page'].includes(key)) {
                const value = this.searchParams[key];
                if (key === 'userIds' && value.length === 1 && value[0] === this.user.id) {
                    // because for Show only my tests we plan on calling test results api ...
                    continue;
                }
                if (value?.length > 0 || value) {
                    return true;
                }
            }
        }
        return false;
    }

    listenRealTimeUpdates() {
        this.statusUpdates$ = this.liveRealtimeService.connect().subscribe({
            next: (data) => {
                this.gridApi.forEachNode(node => {
                    if (data.id === node.data.id) {
                        const updatedData = { ...node.data, ...data };
                        updatedData.delay = this.formatDelay(updatedData);
                        if (data.messageState || data.testStatus) {
                            updatedData.dlrRecStatus = this.formatDlrRecStatus(updatedData);
                        }
                        if (data.smscInfoDto) {
                            updatedData.phoneSmsc = data.smscInfoDto;
                        }
                        node.updateData(updatedData);
                    }
                });
            },
            error: (e) => {
                console.error('Error: ', e);
            },
            complete: () => console.info('Connection closed')
        });
    }

    getPrimaryRequest(): Observable<TestResultsCollection> {
        const params = new AllRequestParams();
        params.size = this.paginationPageSize;
        params.page = this.currentPageNumber - 1;

        if (this.shouldSearch()) {
            return this.service.searchResults(params, this.searchParams);
        } else {
            if (this.searchParams?.userIds) {
                params.onlyMyTests = this.searchParams.userIds.length > 0;
            }
            return this.service.results(params);
        }
    }

    getBackupRequest(): Observable<TestResultsCollection> | undefined {
        const testIdsTobeQueriedForBackUp = this.rowData?.filter(_ => _.isBackupShown).map(_ => `${_.relatedTestId}`);
        if (testIdsTobeQueriedForBackUp?.length) {
            const searchParams = {
                createdAtFrom: null,
                createdAtTo: null,
                destinations: [],
                ids: testIdsTobeQueriedForBackUp,
                telqIds: null,
                messageStates: [],
                senders: [],
                sessionSupplierIds: [],
                testStatuses: [],
                userIds: [],
                taskId: null,
            }
            const backupParams = new AllRequestParams();
            backupParams.size = 0;
            backupParams.page = 0;
            return this.service.searchResults(backupParams, searchParams);
        }
    }

    handleSuccess(results) {
        const primaryResults = results.primaryRequest;
        const backUpResults = results.backupRequest;
        const testResults = [];
        const testIds = [];

        const backUpShownTestIds = new Set(this.rowData ? this.rowData.filter(test => test.isBackupShown).map(test => test.id) : []);

        primaryResults.content.forEach(res => {
            const isBackupShown = backUpShownTestIds.has(res.id);
            res.isBackupShown = isBackupShown;
            res.createdAtFormat = moment(res.createdAt).format('DD/MM/YY HH:mm:ss').split(" ");

            testResults.push(res);
            testIds.push(res.id);

            if (isBackupShown && res.relatedTestId && backUpResults) {
                const backUpTestForTestId = backUpResults.content.find(test => test.id === res.relatedTestId);
                if (backUpTestForTestId) {
                    backUpTestForTestId.createdAtFormat = moment(backUpTestForTestId.createdAt).format('DD/MM/YY HH:mm:ss').split(" ");
                    backUpTestForTestId.isBackup = true;
                    testResults.push(backUpTestForTestId);
                }
            }
        });

        this.totalRowsCount = primaryResults.totalElements;
        this.rowData = testResults;
        this.gridApi.updateGridOptions({ rowData: this.rowData });
        setTimeout(() => {
            this.gridApi.forEachNode(node => {
                if (this.batchSelectedIds.has(node.data.id)) {
                    node.setSelected(true);
                }
            });
        }, 0);

        this.loading = false;
        this.liveRealtimeService.sendMessage({ "trackedTestIds": testIds });
    }


    handleError(e) {
        this.loading = false;
        this.notificationService.error({
            title: 'Live number testing',
            message: 'An error occurred while loading the results',
            serviceName: 'NTC',
            requestMessage: e.statusText,
            requestCode: e.status,
            ts: e.timestamp ? e.timestamp : null
        });
    }

    update(loading = true) {
        if (!this.user.enabled) { return; }
        if (this.batchRepeatRows.length > 0) {
            this.handleBatchRepeat();
            return;
        }

        this.loading = loading;

        if (this.taskId) {
            this.searchParams = {
                taskIds: [this.taskId],
                userIds: [],
                commentText: null,
                commentDateFrom: null,
                commentDateTo: null,
                text: ''
            };
        }

        let requests: {
            primaryRequest?: Observable<TestResultsCollection>,
            backupRequest?: Observable<TestResultsCollection>
        } = {
            primaryRequest: this.getPrimaryRequest(),
            backupRequest: this.getBackupRequest()
        };

        const filteredRequests = Object.entries(requests)
            .filter(([key, val]) => val !== undefined)
            .reduce((acc, [key, val]) => {
                acc[key] = val;
                return acc;
            }, {});

        forkJoin(filteredRequests).subscribe({
            next: (results) => this.handleSuccess(results),
            error: (e) => this.handleError(e)
        });
    }

    onAction(event) {
        this.actions.emit(event);
        if (event.name === 'copy') {
            this.testCaseDetails = [];
            this.modal.alert().dialogClass('modal-dialog large-modal').component(this.copyModalTpl).open();
            this.service.getResultDetailsText(event.row).subscribe(details => {
                this.testCaseDetails = this.getFormattedData(details);
                this.currentSection = this.testCaseDetails[0].id;
            }, e => {
                console.error(e);
            });
        }

        if (event.name === 'addPhonenumberReport') {
            this.phonenumberReportWidget.open(event.row.destinationDto.phone);
        }

        if (event.name === 'appscriptsUser') {
            this.router.navigate(['/appscripts/numbers'], { queryParams: { phonenumber: event.row.destinationDto.phone } })
        }

        if (event.name === 'backUpShow') {
            this.service.one(event.row.relatedTestId).subscribe(
                backup => this.insertBackup(backup),
                error => {
                    this.notificationService.error('Error fetching the backup test.', 'Backup test');
                }
            );
        }

        if (event.name === 'backUpHide') {
            this.removeBackup(event.row);
        }

        if (event.name === 'repeat') {
            console.log(event.row);
            this.onRepeat.emit(event.row);
        }

        if (event.name === 'reimburse') {
            this.reimburseTest(event.row);
        }

        if (event.name === 'comment') {
            const modal = (comment) => {
                this.commentModel = {
                    testId: event.row.id,
                    commentId: comment ? comment.id : null,
                    text: comment ? comment.text : '',
                    changedAt: comment ? new Date(comment.changedAt) : null,
                    changedUser: comment ? comment.user : null,
                };
                this.commentModal = this.modal.alert().dialogClass('modal-dialog small-modal').component(this.commentModalTpl).open();
            }
            if (event.row.commentId) {
                this.loading = true;
                this.service.getCommentById(event.row.id, event.row.commentId).subscribe(comment => {
                    modal(comment);
                    this.loading = false;
                }, error => {
                    this.notificationService.error({
                        title: 'Test comments',
                        message: 'An error occurred while receiving text comment',
                        serviceName: 'NTC',
                        requestMessage: error.statusText,
                        requestCode: error.status,
                        ts: error.timestamp ? error.timestamp : null
                    });
                    this.loading = false;
                })
            } else {
                modal(null);
            }
        }
    }

    reimburseTest(row: TestResultAction): void {
        const dialogRef = this.modal.alert().dialogClass('modal-dialog small-modal').component(this.reimburseModalTpl).open();
        dialogRef.result.then((result) => {
            if (result) {
                this.loading = true;
                this.service.reimburse([row.id]).subscribe({
                    next: (_) => {
                        this.notificationService.success('Test reimbursed', 'Test reimbursed');
                        this.update();
                    },
                    error: (e) => {
                        this.notificationService.error({
                            title: 'Reimburse test',
                            message: 'An error occurred while reimbursing the test',
                            serviceName: 'NTC',
                            requestMessage: e.statusText,
                            requestCode: e.status,
                            ts: e.timestamp ? e.timestamp : null
                        });
                        this.loading = false;
                    }
                });
            } else {
                this.notificationService.info('Reimbursement canceled', 'Reimbursement canceled');
            }
        });
    }

    insertBackup(backup: TestResultAction) {
        const parent: TestResultAction = this.rowData.find(row => row.relatedTestId === backup.id);
        if (!parent) return;
        const index = this.rowData.indexOf(parent);
        parent.isBackupShown = true;
        backup.isBackup = true;
        backup.createdAtFormat = moment(backup.createdAt).format('DD/MM/YY HH:mm:ss').split(" ");

        this.rowData.splice(index, 1, parent);
        this.rowData.splice(index + 1, 0, backup);
        this.gridApi.updateGridOptions({ rowData: this.rowData });
    }

    removeBackup(parent: TestResultAction) {
        parent.isBackupShown = false;
        const index = this.rowData.indexOf(parent);

        this.rowData.splice(index, 1, parent);
        this.rowData = this.rowData.filter(row => row.id !== parent.relatedTestId);
        this.gridApi.updateGridOptions({ rowData: this.rowData });
    }

    onClickExport() {
        this.exportSize = 0;
        this.exportSpinner = false;
        this.exportData = {};
        this.modal.alert().component(this.exportModalTpl).open();
    }

    onAfterSaveComment() {
        if (this.commentModal) {
            this.commentModal.close();
        }
        this.update(true);
    }

    export(limit) {
        this.exportSpinner = true;
        let params = this.searchParams;
        const ids = LntSearchFormComponent.getSaveUserIds(this.localStorage, this.user);
        if (!this.searchParams && ids.length) {
            params = { userIds: ids }
        }

        this.exportService.export('lnt', limit === 'All' ? null : limit, params ? params : {}).then(exportData => {
            this.exportData = exportData;
            this.exportSpinner = false;
        }).catch(error => {
            this.notificationService.error({
                title: 'Live number testing',
                message: 'An error occurred while export tests',
                serviceName: 'NTC',
                requestMessage: error.statusText,
                requestCode: error.status,
                ts: error.timestamp ? error.timestamp : null
            });
            this.exportSpinner = false;
        });
    }

    formatStatus(s, row) {
        return [row.smppStatus, row.testStatus].join('<br>');
    }

    formatConnection(row, html = true) {
        let className = '';
        return html ? `<span class="${className}">${BrowserUtils.escapeHtml(row.supplierDto.title)} ${BrowserUtils.escapeHtml(row.supplierDto.routeType)}</span>` : BrowserUtils.escapeHtml(row.supplierDto.title + ' ' + row.supplierDto.routeType);
    }

    formatDelay(row, html = true) {
        let text = [];
        if (typeof row.smppDelay === 'number') {
            let delay = CustomUtils.secToHumanTime(row.smppDelay)
            text.push(row.smppDelay >= 300 ? `<span class="text-accent-a2">${delay}</span>` : delay);
        }
        if (typeof row.receiptDelay === 'number') {
            let delay = CustomUtils.secToHumanTime(row.receiptDelay)
            text.push(row.receiptDelay >= 300 ? `<span class="text-accent-a2">${delay}</span>` : delay);
        }
        return `<span>${text.join('/')}</span>`;
    }

    openSummaryModal(dialogRef: DialogRef, row: TestResultAction) {
        dialogRef.result.then((result) => {
            if (result === 'viewSummary') {
                this.service.announceAction({ name: 'copy', row: row, column: 'action' })
            }
        });
    }

    onCellClick(event: CellClickedEvent) {
        if (event.colDef.field === 'textDeliveredFull') {
            let value1 = event.data.textSentBackend;
            let value2 = event.data.textDeliveredFull;
            this.setDiff('Text sent', value1, 'Text delivered', value2, true, event.data.telqId);
            const dialogRef = this.modal.alert().component(this.textModalTpl).open();
            this.openSummaryModal(dialogRef, event.data);
        }

        if (event.colDef.field === 'senderDelivered') {
            let value1 = event.data.senderSent;
            let value2 = event.data.senderDelivered;
            this.setDiff('Sender ID sent', value1, 'Sender ID delivered', value2, false);
            const dialogRef = this.modal.alert().dialogClass('modal-dialog small-modal').component(this.senderModalTpl);
            if (value1 !== value2) {
                this.service.pduDetails(event.data.id).subscribe({
                    next: (pdu) => {
                        const pduData = pdu.deliveredSmsPduData.pdusDelivered[0];
                        const parsedPdu = this.service.parsePdu(pduData);
                        const senderType = parsedPdu.sender_type;
                        let decodedSender = parsedPdu.sender as string;
                        if (senderType.charAt(0) == 'D' && decodedSender.endsWith('@')) {
                            decodedSender = decodedSender.substring(0, decodedSender.length - 1);
                        }
                        this.diff.senderPduHex = (decodedSender !== value2) ? decodedSender : null;
                        dialogRef.open();
                    }
                });
            } else {
                this.diff.senderPduHex = null;
                dialogRef.open();
            }
            this.openSummaryModal(dialogRef, event.data);
        }

        if ((event.colDef.field === 'phoneSmsc' || event.colDef.field === 'phone' || event.colDef.field === 'smsc') && event.data.smscInfoDto) {
            let hlr = event.data.smscInfoDto;
            this.smscData.test.phone = event.data.destinationDto.phone;
            this.smscData.test.country = event.data.destinationDto.countryName;
            if (!hlr.phone) {
                this.service.pduDetails(event.data.id).subscribe({
                    next: (pdu) => {
                        const pduData = pdu.deliveredSmsPduData.pdusDelivered[0];
                        const parsedPdu = this.service.parsePdu(pduData);
                        this.smscData.smsc = {
                            phone: null,
                            phoneFromPdu: parsedPdu.smsc,
                            country: null,
                            provider: null,
                            mcc: null,
                            mnc: null
                        };
                        const dialogRef = this.modal.alert().dialogClass('modal-dialog small-modal').component(this.smscModalTpl).open();
                        this.openSummaryModal(dialogRef, event.data);
                    }
                });
            } else {
                this.smscData.smsc.phone = hlr.phone;
                this.smscData.smsc.phoneFromPdu = null;
                this.smscData.smsc.country = hlr.countryName;
                this.smscData.isError = hlr.countryName !== event.data.destinationDto.countryName;
                this.smscData.smsc.mnc = hlr.mnc ? hlr.mnc : null;
                this.smscData.smsc.mcc = hlr.mcc ? hlr.mcc : null;
                this.smscData.smsc.provider = hlr.providerName ? hlr.providerName : null;
                const dialogRef = this.modal.alert().dialogClass('modal-dialog small-modal').component(this.smscModalTpl).open();
                this.openSummaryModal(dialogRef, event.data);
            }
        }

        if (event.colDef.field === 'supplierDto') {
            this.connectionSpinner = true;
            this.connectionService.one(event.data.sessionId).subscribe(session => {
                this.supplier = event.data.supplierDto;
                this.session = session;
                this.connectionSpinner = false;
            }, () => this.connectionSpinner = false);
            this.modal.alert().component(this.connectionModalTpl).open();
        }

        if (event.colDef.field === 'delay') {
            const testId = event.data.id;
            this.latencySpinner = true;
            this.service.getSmppDelayByTestId(testId).subscribe({
                next: (response) => {
                    if (response) {
                        this.smppChartMessageTitle = null;
                        this.smppChartMessageDesc = null;
                        this.smsChartMessageTitle = null;
                        this.smppChartOptions = null;
                        this.smsChartOptions = null;
                        this.populateSmppChart(event, response);
                        this.populateSmsChart(event, response);
                    }
                    else {
                        this.smppChartMessageTitle = "No data available for this test.";
                        this.smppChartMessageDesc = "Please note that this feature is still in development for multi-part SMS.";
                        this.latencySpinner = false;
                    }
                    this.modal.alert().dialogClass('modal-dialog extra-large-modal').component(this.latencyModalTpl).open().onDestroy.subscribe(() => {
                        this.smppChartOptions = null;
                        this.smsChartOptions = null;
                    });
                },
                error: (error) => {
                    this.smppChartMessageTitle = "No data available for this test.";
                    this.smppChartMessageDesc = "Please note that this feature is still in development for multi-part SMS.";
                    this.latencySpinner = false;
                    this.modal.alert().dialogClass('modal-dialog extra-large-modal').component(this.latencyModalTpl).open().onDestroy.subscribe(() => {
                        this.smppChartOptions = null;
                        this.smsChartOptions = null;
                    });
                },
                complete: () => { this.latencySpinner = false; }
            });
        }
    }

    populateSmppChart(event: CellClickedEvent, response) {
        this.selectedTestIdForCharts = event.data.id;
        const testCreatedTimestamp = event.data.createdAt;
        const smppSubmittedTimestamp = response.submitSmTimestamp;
        const smppResponseTimestamp = response.submitSmResponseTimestamp;
        const dlrTimestamp = response.dlrTimestamp;

        const submitSM = moment.duration(moment(smppSubmittedTimestamp).diff(moment(testCreatedTimestamp))).asSeconds();
        const submitSMResponse = moment.duration(moment(smppResponseTimestamp).diff(moment(smppSubmittedTimestamp))).asSeconds();
        const dlr = moment.duration(moment(dlrTimestamp).diff(moment(smppResponseTimestamp))).asSeconds();

        this.smppChartOptions = {
            credits: { enabled: false },
            chart: { type: 'bar', backgroundColor: 'transparent' },
            title: {
                text: 'SMPP Latency Details',
            },
            tooltip: {
                split: true,
                positioner: function (labelWidth, labelHeight, point) {
                    return {
                        x: point.plotX / 2.5,
                        y: point.plotY - (labelHeight / 2.5)
                    }
                },
            },
            plotOptions: {
                series: {
                    stacking: 'normal',
                },
            },
            xAxis: {
                categories: ['SMSC'],
            },
            yAxis: {
                min: 0,
                title: {
                    text: 'Seconds',
                },
                stackLabels: {
                    enabled: true,
                    format: '{total} s'
                },
            },
            legend: {
                reversed: true
            },
            series: [{
                name: 'deliver_sm',
                type: 'bar',
                color: '#59c4ff',
                data: [dlr],
                tooltip: {
                    pointFormat: `
                        <b>Interval: </b> ${dlr}s <br>
                        <b>Timestamp: </b> ${dlrTimestamp}`
                }
            },
            {
                name: 'submit_sm response',
                type: 'bar',
                color: '#007aff',
                data: [submitSMResponse],
                tooltip: {
                    pointFormat: `
                        <b>Interval: </b> ${submitSMResponse}s <br>
                        <b>Timestamp: </b> ${smppResponseTimestamp}`
                }
            },
            {
                name: 'submit_sm',
                type: 'bar',
                color: '#033dab',
                data: [submitSM],
                tooltip: {
                    pointFormat: `
                        <b>Interval: </b> ${5000}s <br>
                        <b>Timestamp: </b> ${smppSubmittedTimestamp}`
                }
            }],
        };
    }

    populateSmsChart(event: CellClickedEvent, response): void {
        const testCreatedTimestamp = event.data.createdAt;
        const smscTimestamp = response.smscTimestamp;
        const phoneTimestamp = response.phoneTimestamp;
        const receivedSmsTimestamp = response.receivedSmsTimestamp;

        if (!smscTimestamp || !phoneTimestamp || !receivedSmsTimestamp) {
            this.smsChartMessageTitle = "No data available for this test.";
            return;
        }
        const submittedBySmsc = moment.duration(moment(smscTimestamp).diff(moment(testCreatedTimestamp))).asSeconds();
        const receivedOnHandset = moment.duration(moment(phoneTimestamp).diff(moment(smscTimestamp))).asSeconds();
        const receivedOnServer = moment.duration(moment(receivedSmsTimestamp).diff(moment(phoneTimestamp))).asSeconds();

        if (submittedBySmsc < 0 || receivedOnHandset < 0 || receivedOnServer < 0) {
            this.smsChartMessageTitle = "No data available for this test.";
            return;
        }

        this.smsChartOptions = {
            credits: { enabled: false },
            chart: { type: 'bar', backgroundColor: 'transparent' },
            title: {
                text: 'Received SMS Time Details',
            },
            tooltip: {
                split: true,
                positioner: function (labelWidth, labelHeight, point) {
                    return {
                        x: point.plotX / 2.5,
                        y: point.plotY - (labelHeight / 2.5)
                    }
                },
            },
            plotOptions: {
                series: {
                    stacking: 'normal',
                },
            },
            xAxis: {
                // This two property below is to align both graphs .. SMPP and SMS ...
                offset: 9,
                lineWidth: 0,
                categories: ['SMS'],
            },
            yAxis: {
                min: 0,
                title: {
                    text: 'Time in Seconds',
                },
                stackLabels: {
                    enabled: true,
                    format: '{total} s'
                },
            },
            legend: {
                reversed: true
            },
            series: [
                {
                    name: 'Received on Server',
                    type: 'bar',
                    color: '#AFE1AF',
                    data: [receivedOnServer],
                    tooltip: {
                        pointFormat: `
                            <b>Interval: </b> ${receivedOnServer}s <br>
                            <b>Timestamp: </b> ${receivedSmsTimestamp}`
                    }
                },
                {
                    name: 'Received on Phone*',
                    type: 'bar',
                    color: '#5fc297',
                    data: [receivedOnHandset],
                    tooltip: {
                        pointFormat: `
                            <b>Interval: </b> ${receivedOnHandset}s <br>
                            <b>Timestamp: </b> ${phoneTimestamp}`
                    }
                },
                {
                    name: 'SMSC Timestamp*',
                    type: 'bar',
                    color: '#18a689',
                    data: [submittedBySmsc],
                    tooltip: {
                        pointFormat: `
                            <b>Interval: </b> ${submittedBySmsc}s <br>
                            <b>Timestamp: </b> ${smscTimestamp}`
                    }
                },
            ],
        };

    }

    setDiff(title1, value1, title2, value2, diff = false, telqId = null) {
        let isReplace = value1 !== value2;
        let textValue1 = (value1 === null ? 'NULL' : value1);
        let textValue2 = (value2 === null ? 'NULL' : value2);
        textValue1 = textValue1
            .replaceAll("\r\n", "\\r\\n\r\n")
            .replaceAll("\n", "\\n\n")
            .replaceAll("\r", "\\r\r")
            .replaceAll("\t", "\\t\t");
        textValue2 = textValue2
            .replaceAll("\r\n", "\\r\\n\r\n")
            .replaceAll("\n", "\\n\n")
            .replaceAll("\r", "\\r\r")
            .replaceAll("\t", "\\r\t");
        this.diff.title = title1 + '/' + title2;
        this.diff.title1 = title1;
        this.diff.value1 = BrowserUtils.sanitizeHTML(textValue1);
        this.diff.title2 = title2;
        let className = '';
        if (value1 !== null && value2 !== null) {
            className = isReplace ? 'text-error' : 'text-sucess';
        }
        const content = BrowserUtils.sanitizeHTML(textValue2);
        this.diff.value2 = `<span class="${className}">${content}</span>`;
        this.diff.result = null;
        if (diff === true && value1 !== null && value2 !== null) {
            this.diff.result = this.getDiff(textValue1, textValue2);
        }
        if (telqId) {
            const telqIdContent = `<span title="Test ID Text" class="text-accent">${telqId}</span>`;
            this.diff.value1 = this.diff.value1.replace(telqId, telqIdContent);
            this.diff.value2 = this.diff.value2.replace(telqId, telqIdContent)
        }
        if (this.diff.value1.startsWith(' ')) {
            this.diff.value1 = this.diff.value1.replace(' ', `<span title="Space" class="space-highlight">&nbsp;</span>`);
        }
        if (this.diff.value1.endsWith(' ')) {
            this.diff.value1 = this.diff.value1.replace(/.$/, `<span title="Space" class="space-highlight">&nbsp;</span>`);
        }
    }

    getDiff(value1, value2): string {
        let html = '';
        let classNameDef = {
            added: 'text-error',
            removed: 'text-normal',
            normal: 'text-sucess'
        };
        diffChars(value1, value2).map(block => {
            let state = 'normal';
            let isChanged = false;
            if (block.added) {
                state = 'added';
                isChanged = true;
            } else if (block.removed) {
                state = 'removed';
                isChanged = true;
            }

            let className = classNameDef[state];
            if (isChanged && block.value === ' ') {
                className += ' space-highlight';
            }
            const content = BrowserUtils.sanitizeHTML(block.value);
            html += `<span class="${className}">${content === ' ' ? '&nbsp;' : content}</span>`;
        });

        return html;
    }

    getFormattedData(details: TestDetails[]): TestDetails[] {
        details.forEach(section => {
            section.data = section.data.map(detail => {
                const [first, ...rest] = detail.split(':');
                return [first, rest.join(':')];
            });
        });
        return details;
    }

    onSectionChange(sectionId: string) {
        this.currentSection = sectionId ? sectionId : this.testCaseDetails[0].id;
    }

    scrollTo(sectionId: string) {
        document.querySelector('#' + sectionId).scrollIntoView();
        setTimeout(() => {
            // Set timeout necessary because scrollspy will trigger on section change when document scrollBy is triggered...
            this.onSectionChange(sectionId);
        }, 0);
    }

    // ng grid 

    onGridReady(params: GridReadyEvent) {
        this.gridApi = params.api;
        this.addHorizontalScrollOnTop();
        const columnState = this.localStorage.get(`lnt_table_state_${this.user.id}`);
        this.update();
        this.gridApi.applyColumnState({ state: columnState, applyOrder: true });
    }

    onRowSelected(event: RowSelectedEvent) {
        const selectedRows = this.gridApi.getSelectedRows();
        this.batchSelectedIds = new Set(selectedRows.map(row => row.id));
        this.changeBatch.emit(selectedRows);
    }

    resetBatch() {
        this.batchSelectedIds.clear();
        this.gridApi.deselectAll();
    }

    getContextMenuItems = (
        params: GetContextMenuItemsParams
    ): (MenuItemDef)[] => {
        const currentRowClicked = params.node.data;
        const result: (MenuItemDef)[] = [
            {
                name: 'Test Case Details ',
                icon: '<span class="icon-tests-settings fs-4"></span>',
                action: () => {
                    this.service.announceAction({ name: 'copy', row: currentRowClicked, column: 'action' })
                },
            },
            {
                name: 'Repeat batch',
                icon: '<span class="icon-refresh fs-4"></span>',
                action: () => {
                    this.onAction({ name: 'repeat', row: currentRowClicked, column: 'action' })
                },
            },
            {
                name: 'Copy',
                icon: '<span class="icon-copy fs-4"></span>',
                action: () => {
                    const renderedValue = this.getRenderedCellValue(params);
                    this.clipBoard.copy(renderedValue ? renderedValue : currentRowClicked.id);
                },
            },
            {
                name: 'Show/Add comment',
                icon: '<span class="icon-comment fs-4"></span>',
                action: () => {
                    this.onAction({ name: 'comment', row: currentRowClicked, column: 'action' })
                }
            },
            {
                name: 'Phone number report',
                icon: '<span class="icon-bug ms-0_2 fs-4"></span>',
                action: () => {
                    this.onAction({ name: 'addPhonenumberReport', row: currentRowClicked, column: 'action' })
                }
            },
            {
                name: 'Reimburse test',
                icon: '<span class="far fa-money-bill-alt ms-0_2 fs-4"></span>',
                action: () => {
                    this.onAction({ name: 'reimburse', row: currentRowClicked, column: 'action' })
                }
            }
        ];
        if (this.isAdmin) {
            return result;
        }
        return result.filter(item => item.name !== 'Reimburse test');
    }

    // ng grid

    ngOnDestroy() {
        if (this.intervalTimer) {
            clearInterval(this.intervalTimer);
        }
        this.actionSubscription.unsubscribe();
        this.columnChange$.unsubscribe();
        this.mutationObserver.disconnect();
        if (this.statusUpdates$) {
            this.statusUpdates$.unsubscribe();
        }
        this.liveRealtimeService.close();
    }
}
